import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FormListType,
  ResumeType,
  ResumeStateType,
  IndexActionPayloadType,
  FormListNameType,
  IndexActionPayloadTypeDate,
  IndexActionPayloadBooleanType,
  IndexActionPayloadNumberType,
  BasicIntroFormType,
  MultipleIndexActionPayloadType,
  IndexActionPayloadListType,
  SetResumePortfolioFormLinkTypePayloadType,
  ResumeEduTypePayloadType,
  FormErrorType,
  SchoolFormType,
  PortfolioFormType,
  CareerFormType,
  ProjectFormType,
  EducationTrainingFormType,
  AwardFormType,
  LicenseFormType,
  LanguageAbilityFormType,
  OAskillFormType,
  IntroduceFormType,
  DeleteFormError,
  DescriptionPayloadType,
} from '#types/resume';

const FormList: FormListType = {
  portfolioForm: {
    type: null,
    link: '',
  },
  schoolForm: {
    schoolSystem: '',
    schoolName: '',
    schoolLocation: '',
    entryYear: null,
    graduationYear: null,
    major: '',
    score: '',
    schoolStatus: '',
    description: '-\n-\n-',
  },
  careerForm: {
    companyName: '',
    departmentName: '',
    description: [
      {
        order: 1,
        content: '-\n-\n-',
        subContent: '',
      },
    ],
    entryDate: null,
    hireType: '',
    isWork: '',
    leaveDate: null,
    position: '',
  },
  projectForm: {
    description: '-\n-\n-',
    link: '',
    endDate: null,
    overseeAgency: '',
    projectName: '',
    startDate: null,
  },
  externalActivityForm: {
    activityName: '',
    activityType: '',
    description: '-\n-\n-',
    endDate: null,
    overseeAgency: '',
    startDate: null,
  },
  educationTrainingForm: {
    eduType: '',
    description: '-\n-\n-',
    eduInstitution: '',
    eduName: '',
    endDate: null,
    startDate: null,
  },
  languageAbilityForm: {
    description: '-\n-\n-',
    language: '',
  },
  OAskillForm: {
    activityProgram: '',
    description: '-\n-\n-',
    skillLevel: '',
  },
  licenseForm: {
    id: null,
    acquireDate: null,
    description: '-\n-\n-',
    issueAgency: '',
    licenseName: '',
    score: '',
    isDirect: false,
  },

  awardForm: {
    awardAgency: '',
    awardDay: null,
    awardName: '',
    description: '-\n-\n-',
  },

  introduceForm: {
    title: '',
    description: '',
  },
};

export const initialResumeData: ResumeType = {
  id: 0,
  title: '',
  errors: [],
  basicIntroForm: [
    {
      order: 0,
      content: '-\n-\n-',
    },
  ],
  jobValueForm: {
    jobValue: [
      {
        value: '',
        order: 1,
      },
      {
        value: '',
        order: 2,
      },
      {
        value: '',
        order: 3,
      },
      {
        value: '',
        order: 4,
      },
    ],
    annualSalary: '',
    description: '-\n-\n-',
  },
  personalForm: {
    name: '',
    gender: '',
    birthday: new Date('1990-01-01T00:00:00.000Z'),
    state: '',
    city: '',
    militeryStatus: '',
    email: '',
    phone: '',
    profileImage: {
      fileName: '',
      filePath: '',
    },
  },
  skillForm: [],
  schoolForm: [],
  portfolioForm: [],
  careerForm: [],
  projectForm: [],
  externalActivityForm: [],
  educationTrainingForm: [],
  languageAbilityForm: [],
  OAskillForm: [],
  licenseForm: [],
  awardForm: [],
  introduceForm: [],
  itemSetting: [
    { name: '이력서 제목', formName: 'title', isVisible: true, isRequired: true },
    { name: '간단 소개', formName: 'basicIntroForm', isVisible: true, isRequired: true },
    { name: '직업가치관', formName: 'jobValueForm', isVisible: true, isRequired: true },
    { name: '인적사항', formName: 'personalForm', isVisible: true, isRequired: true },
    {
      name: '학력사항',
      formName: 'schoolForm',
      isVisible: false,
      isRequired: false,
    },
    {
      name: '포트폴리오',
      formName: 'portfolioForm',
      isVisible: false,
      isRequired: false,
    },
    { name: '경력', formName: 'careerForm', isVisible: false, isRequired: false },
    { name: '프로젝트', formName: 'projectForm', isVisible: false, isRequired: false },
    {
      name: '활동 및 교육',
      formName: 'educationTrainingForm',
      isVisible: false,
      isRequired: false,
    },
    { name: '수상경력', formName: 'awardForm', isVisible: false, isRequired: false },
    { name: '자격증', formName: 'licenseForm', isVisible: false, isRequired: false },
    {
      name: '스킬',
      formName: 'skillForm',
      isVisible: false,
      isRequired: false,
    },
    {
      name: '어학능력',
      formName: 'languageAbilityForm',
      isVisible: false,
      isRequired: false,
    },
    {
      name: 'OA 활용 능력',
      formName: 'OAskillForm',
      isVisible: false,
      isRequired: false,
    },
    {
      name: '자기소개',
      formName: 'introduceForm',
      isVisible: false,
      isRequired: false,
    },
  ],
  createdAt: null,
  updatedAt: null,
  urlSlug: '',
  filePath: '',
  isDeleted: false,
  userId: 0,
  isTemp: true,
};

const initialState: ResumeStateType = {
  isDirtyWritingResume: false,
  isSubmitResume: false,
  isUploadingResume: false,
  isTempSubmit: false,
  resume: initialResumeData,
};

const resume = createSlice({
  name: 'resume',
  initialState,
  reducers: {
    initializeResume(state) {
      state.resume = initialState.resume;
    },
    deleteFormError(state, action: PayloadAction<DeleteFormError>) {
      state.resume.errors = state.resume.errors?.map(formError => {
        const filteredErrors = formError.errors.filter(
          error => !error.key.includes(action.payload.key),
        );
        return { ...formError, errors: filteredErrors };
      });
    },
    setFormErrors(state, action: PayloadAction<FormErrorType>) {
      const targetFormName = action.payload.formName;
      const targetErrors = action.payload.errors;
      const existingErrorIndex = state.resume.errors?.findIndex(
        item => item.formName === targetFormName,
      );

      if (existingErrorIndex === -1) {
        if (action.payload.errors.filter(error => error.message !== '').length === 0) return;
        state.resume.errors?.push(action.payload);
        return;
      }
      if (!state.resume.errors) return;
      const updatedErrors = state.resume.errors[existingErrorIndex || 0].errors.map(oldError => {
        const matchingNewError = targetErrors.find(error => error.key === oldError.key);
        return matchingNewError || oldError;
      });

      targetErrors.forEach(error => {
        if (!updatedErrors.some(oldError => oldError.key === error.key)) {
          updatedErrors.push(error);
        }
      });
      state.resume.errors[existingErrorIndex || 0].errors = updatedErrors.filter(
        item => item.message !== '',
      );
    },
    setIsDirtyWritingResume(state, action: PayloadAction<boolean>) {
      state.isDirtyWritingResume = action.payload;
    },

    setisTempSubmitResume(state, action: PayloadAction<boolean>) {
      state.isTempSubmit = action.payload;
    },

    setIsSubmitResume(state, action: PayloadAction<boolean>) {
      state.isSubmitResume = action.payload;
    },

    setIsUploadingResume(state, action: PayloadAction<boolean>) {
      state.isUploadingResume = action.payload;
    },

    setResume(state, action: PayloadAction<ResumeType>) {
      state.resume = action.payload;
    },

    //여기부터 이력서 생성 관련 리듀서
    setResumeId(state, action: PayloadAction<number>) {
      state.resume.id = action.payload;
    },

    setResumeBasicIntroFormValue(state, action: PayloadAction<BasicIntroFormType>) {
      state.resume.basicIntroForm = state.resume.basicIntroForm.map(item =>
        item.order === action.payload.order ? action.payload : item,
      );
    },

    setResumeBasicIntroForm(state, action: PayloadAction<BasicIntroFormType[]>) {
      state.resume.basicIntroForm = action.payload;
    },

    setResumeTitle(state, action: PayloadAction<string>) {
      state.resume.title = action.payload;
    },

    //** 직업 가치관 */
    setResumeJobValueFormJobValueItem(
      state,
      action: PayloadAction<{ value: string; order: number }>,
    ) {
      const { value, order } = action.payload;
      state.resume.jobValueForm.jobValue = state.resume.jobValueForm.jobValue.map(item => {
        if (item.value === value) return { order: item.order, value: '' };
        if (item.order === order) return { order, value };
        return item;
      });
    },
    setResumeJobValueFormAnnualSalary(state, action: PayloadAction<string>) {
      state.resume.jobValueForm.annualSalary = action.payload;
    },
    setResumeJobValueFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { value } = action.payload;
      state.resume.jobValueForm.description = value;
    },

    //** 인적사항 */
    setResumePersonalFormName(state, action: PayloadAction<string>) {
      state.resume.personalForm.name = action.payload;
    },
    setResumePersonalFormGender(state, action: PayloadAction<string>) {
      state.resume.personalForm.gender = action.payload;
    },
    setResumePersonalFormBirthday(state, action: PayloadAction<Date | null>) {
      state.resume.personalForm.birthday = action.payload;
    },
    setResumePersonalFormState(state, action: PayloadAction<string>) {
      state.resume.personalForm.state = action.payload;
    },
    setResumePersonalFormCity(state, action: PayloadAction<string>) {
      state.resume.personalForm.city = action.payload;
    },
    setResumePersonalFormMiliteryStatus(state, action: PayloadAction<string>) {
      state.resume.personalForm.militeryStatus = action.payload;
    },
    setResumePersonalFormEmail(state, action: PayloadAction<string>) {
      state.resume.personalForm.email = action.payload;
    },
    setResumePersonalFormPhone(state, action: PayloadAction<string>) {
      state.resume.personalForm.phone = action.payload;
    },
    setResumePersonalFormProfileImage(
      state,
      action: PayloadAction<{
        fileName: string;
        filePath: string;
      }>,
    ) {
      state.resume.personalForm.profileImage = action.payload;
    },

    //** 학력사항 폼 */
    setResumeSchoolFormSchoolSystem(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.schoolSystem = value;
      }
    },

    setResumeSchoolForm(state, action: PayloadAction<SchoolFormType[]>) {
      state.resume.schoolForm = action.payload;
    },

    setResumeSchoolFormSchoolName(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.schoolName = value;
      }
    },

    setResumeSchoolFormSchoolLocation(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.schoolLocation = value;
      }
    },

    setResumeSchoolFormEntryYear(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.entryYear = value;
      }
    },
    setResumeSchoolFormGraduationYear(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.graduationYear = value;
      }
    },

    setResumeSchoolFormSchoolStatus(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.schoolStatus = value;
      }
    },
    setResumeSchoolFormMajor(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.major = value;
      }
    },
    setResumeSchoolFormScore(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.score = value;
      }
    },
    setResumeSchoolFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { value, index } = action.payload;
      const school = state.resume.schoolForm[index];
      if (school instanceof Object && school !== null) {
        school.description = value;
      }
    },
    // setResumeSchoolFormDescriptionList(state, action: PayloadAction<IndexActionPayloadListType>) {
    //   const { index, values } = action.payload;
    //   const school = state.resume.schoolForm[index];
    //   if (school instanceof Object && school !== null) {
    //     school.description = values;
    //   }
    // },

    //** 스킬 폼 */
    setResumeSkillForm(state, action: PayloadAction<string[]>) {
      state.resume.skillForm = action.payload;
    },

    //* 포트폴리오 폼 */
    setResumePortfolioFormLinkType(
      state,
      action: PayloadAction<SetResumePortfolioFormLinkTypePayloadType>,
    ) {
      const { index, link, type } = action.payload;
      if (typeof link === 'string') state.resume.portfolioForm[index].link = link;
      if (type) state.resume.portfolioForm[index].type = type;
    },

    setResumePortfolioForm(state, action: PayloadAction<PortfolioFormType[]>) {
      state.resume.portfolioForm = action.payload;
    },

    //* 경력 폼 */
    setResumeCareerForm(state, action: PayloadAction<CareerFormType[]>) {
      state.resume.careerForm = action.payload;
    },

    setResumeCareerFormCompanyName(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.companyName = value;
      }
    },
    setResumeCareerFormEntryDate(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.entryDate = value;
      }
    },
    setResumeCareerFormHireType(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.hireType = value;
      }
    },
    setResumeCareerFormIsWork(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.isWork = value;
      }
    },
    setResumeCareerFormLeaveDate(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.leaveDate = value;
      }
    },
    setResumeCareerFormPosition(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.position = value;
      }
    },
    setResumeCareerFormDepartmentName(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.departmentName = value;
      }
    },
    setResumeCareerFormDescriptionTitle(
      state,
      action: PayloadAction<MultipleIndexActionPayloadType>,
    ) {
      const { content, order, index } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.description[order] = { ...career.description[order], order, subContent: content };
      }
    },
    setResumeCareerFormDescription(state, action: PayloadAction<MultipleIndexActionPayloadType>) {
      const { content, order, index } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.description[order] = { ...career.description[order], order, content };
      }
    },
    setResumeCareerFormDescriptionList(state, action: PayloadAction<IndexActionPayloadListType>) {
      const { index, values } = action.payload;
      const career = state.resume.careerForm[index];
      if (career instanceof Object && career !== null) {
        career.description = values;
      }
    },

    // ** 프로젝트 폼 */
    setResumeProjectForm(state, action: PayloadAction<ProjectFormType[]>) {
      state.resume.projectForm = action.payload;
    },

    setResumeProjectFormEndDate(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const project = state.resume.projectForm[index];
      if (project instanceof Object && project !== null) {
        project.endDate = value;
      }
    },
    setResumeProjectFormOverseeAgency(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const project = state.resume.projectForm[index];
      if (project instanceof Object && project !== null) {
        project.overseeAgency = value;
      }
    },
    setResumeProjectFormProjectName(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const project = state.resume.projectForm[index];
      if (project instanceof Object && project !== null) {
        project.projectName = value;
      }
    },
    setResumeProjectFormProjectLink(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const project = state.resume.projectForm[index];
      if (project instanceof Object && project !== null) {
        project.link = value;
      }
    },
    setResumeProjectFormStartDate(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const project = state.resume.projectForm[index];
      if (project instanceof Object && project !== null) {
        project.startDate = value;
      }
    },
    setResumeProjectFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { value, index } = action.payload;
      const project = state.resume.projectForm[index];
      if (project instanceof Object && project !== null) {
        project.description = value;
      }
    },
    // setResumeProjectFormDescriptionList(state, action: PayloadAction<IndexActionPayloadListType>) {
    //   const { index, values } = action.payload;
    //   const project = state.resume.projectForm[index];
    //   if (project instanceof Object && project !== null) {
    //     project.description = values;
    //   }
    // },

    //** 대외활동 폼 */

    setResumeExternalActivityFormActivityName(
      state,
      action: PayloadAction<IndexActionPayloadType>,
    ) {
      const { index, value } = action.payload;
      const externalActivity = state.resume.externalActivityForm[index];
      if (externalActivity instanceof Object && externalActivity !== null) {
        externalActivity.activityName = value;
      }
    },

    setResumeExternalActivityFormActivityType(
      state,
      action: PayloadAction<IndexActionPayloadType>,
    ) {
      const { index, value } = action.payload;
      const externalActivity = state.resume.externalActivityForm[index];
      if (externalActivity instanceof Object && externalActivity !== null) {
        externalActivity.activityType = value;
      }
    },
    setResumeExternalActivityFormEndDate(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const externalActivity = state.resume.externalActivityForm[index];
      if (externalActivity instanceof Object && externalActivity !== null) {
        externalActivity.endDate = value;
      }
    },
    setResumeExternalActivityFormOverseeAgency(
      state,
      action: PayloadAction<IndexActionPayloadType>,
    ) {
      const { index, value } = action.payload;
      const externalActivity = state.resume.externalActivityForm[index];
      if (externalActivity instanceof Object && externalActivity !== null) {
        externalActivity.overseeAgency = value;
      }
    },
    setResumeExternalActivityFormStartDate(
      state,
      action: PayloadAction<IndexActionPayloadTypeDate>,
    ) {
      const { index, value } = action.payload;
      const externalActivity = state.resume.externalActivityForm[index];
      if (externalActivity instanceof Object && externalActivity !== null) {
        externalActivity.startDate = value;
      }
    },
    setResumeExternalActivityFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { index, value } = action.payload;
      const externalActivity = state.resume.externalActivityForm[index];
      if (externalActivity instanceof Object && externalActivity !== null) {
        externalActivity.description = value;
      }
    },

    //** 교육사항 폼 */
    setResumeEducationTrainingForm(state, action: PayloadAction<EducationTrainingFormType[]>) {
      state.resume.educationTrainingForm = action.payload;
    },

    setResumeEducationTrainingFormEduInstitution(
      state,
      action: PayloadAction<IndexActionPayloadType>,
    ) {
      const { index, value } = action.payload;
      const educationTraining = state.resume.educationTrainingForm[index];
      if (educationTraining instanceof Object && educationTraining !== null) {
        educationTraining.eduInstitution = value;
      }
    },
    setResumeEducationTrainingFormEduType(state, action: PayloadAction<ResumeEduTypePayloadType>) {
      const { index, value } = action.payload;
      const educationTraining = state.resume.educationTrainingForm[index];
      if (educationTraining instanceof Object && educationTraining !== null) {
        educationTraining.eduType = value;
      }
    },
    setResumeEducationTrainingFormEduName(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const educationTraining = state.resume.educationTrainingForm[index];
      if (educationTraining instanceof Object && educationTraining !== null) {
        educationTraining.eduName = value;
      }
    },
    setResumeEducationTrainingFormEndDate(
      state,
      action: PayloadAction<IndexActionPayloadTypeDate>,
    ) {
      const { index, value } = action.payload;
      const educationTraining = state.resume.educationTrainingForm[index];
      if (educationTraining instanceof Object && educationTraining !== null) {
        educationTraining.endDate = value;
      }
    },
    setResumeEducationTrainingFormStartDate(
      state,
      action: PayloadAction<IndexActionPayloadTypeDate>,
    ) {
      const { index, value } = action.payload;
      const educationTraining = state.resume.educationTrainingForm[index];
      if (educationTraining instanceof Object && educationTraining !== null) {
        educationTraining.startDate = value;
      }
    },
    setResumeEducationTrainingFormDescription(
      state,
      action: PayloadAction<DescriptionPayloadType>,
    ) {
      const { value, index } = action.payload;
      const educationTraining = state.resume.educationTrainingForm[index];
      if (educationTraining instanceof Object && educationTraining !== null) {
        educationTraining.description = value;
      }
    },
    // setResumeEducationTrainingFormDescriptionList(
    //   state,
    //   action: PayloadAction<IndexActionPayloadListType>,
    // ) {
    //   const { index, values } = action.payload;
    //   const educationTraining = state.resume.educationTrainingForm[index];
    //   if (educationTraining instanceof Object && educationTraining !== null) {
    //     educationTraining.description = values;
    //   }
    // },

    //** 어학능력 */
    setResumeLanguageAbilityForm(state, action: PayloadAction<LanguageAbilityFormType[]>) {
      state.resume.languageAbilityForm = action.payload;
    },

    setResumeLanguageAbilityFormLanguage(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const languageAbility = state.resume.languageAbilityForm[index];
      if (languageAbility instanceof Object && languageAbility !== null) {
        languageAbility.language = value;
      }
    },
    setResumeLanguageAbilityFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { index, value } = action.payload;
      const languageAbility = state.resume.languageAbilityForm[index];
      if (languageAbility instanceof Object && languageAbility !== null) {
        languageAbility.description = value;
      }
    },
    // setResumeLanguageAbilityFormDescriptionList(
    //   state,
    //   action: PayloadAction<IndexActionPayloadListType>,
    // ) {
    //   const { index, values } = action.payload;
    //   const languageAbility = state.resume.languageAbilityForm[index];
    //   if (languageAbility instanceof Object && languageAbility !== null) {
    //     languageAbility.description = values;
    //   }
    // },
    //** OA 활용 능력 폼 */
    setResumeOAskillForm(state, action: PayloadAction<OAskillFormType[]>) {
      state.resume.OAskillForm = action.payload;
    },

    setResumeOAskillFormActivityProgram(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const OAskill = state.resume.OAskillForm[index];
      if (OAskill instanceof Object && OAskill !== null) {
        OAskill.activityProgram = value;
      }
    },
    setResumeOAskillFormSkillLevel(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const OAskill = state.resume.OAskillForm[index];
      if (OAskill instanceof Object && OAskill !== null) {
        OAskill.skillLevel = value;
      }
    },
    setResumeOAskillFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { index, value } = action.payload;
      const OAskill = state.resume.OAskillForm[index];
      if (OAskill instanceof Object && OAskill !== null) {
        OAskill.description = value;
      }
    },
    // setResumeOAskillFormDescriptionList(state, action: PayloadAction<IndexActionPayloadListType>) {
    //   const { index, values } = action.payload;
    //   const OAskill = state.resume.OAskillForm[index];
    //   if (OAskill instanceof Object && OAskill !== null) {
    //     OAskill.description = values;
    //   }
    // },

    //**자격증 폼 */
    setResumeLicenseForm(state, action: PayloadAction<LicenseFormType[]>) {
      state.resume.licenseForm = action.payload;
    },

    setResumeLicenseFormId(state, action: PayloadAction<IndexActionPayloadNumberType>) {
      const { index, value } = action.payload;
      const license = state.resume.licenseForm[index];
      if (license instanceof Object && license !== null) {
        license.id = value;
      }
    },

    setResumeLicenseFormAcquireDate(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const license = state.resume.licenseForm[index];
      if (license instanceof Object && license !== null) {
        license.acquireDate = value;
      }
    },
    setResumeLicenseFormIssueAgency(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const license = state.resume.licenseForm[index];
      if (license instanceof Object && license !== null) {
        license.issueAgency = value;
      }
    },
    setResumeLicenseFormLicenseName(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const license = state.resume.licenseForm[index];
      if (license instanceof Object && license !== null) {
        license.licenseName = value;
      }
    },
    setResumeLicenseFormScore(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const license = state.resume.licenseForm[index];
      if (license instanceof Object && license !== null) {
        license.score = value;
      }
    },
    setResumeLicenseFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { index, value } = action.payload;
      const license = state.resume.licenseForm[index];
      if (license instanceof Object && license !== null) {
        license.description = value;
      }
    },
    // setResumeLicenseFormDescriptionList(state, action: PayloadAction<IndexActionPayloadListType>) {
    //   const { index, values } = action.payload;
    //   const license = state.resume.licenseForm[index];
    //   if (license instanceof Object && license !== null) {
    //     license.description = values;
    //   }
    // },
    setResumeLicenseFormIsDirect(state, action: PayloadAction<IndexActionPayloadBooleanType>) {
      const { index, value } = action.payload;
      const license = state.resume.licenseForm[index];
      if (license instanceof Object && license !== null) {
        license.isDirect = value;
      }
    },

    //** 수상경력 폼 */
    setResumeAwardForm(state, action: PayloadAction<AwardFormType[]>) {
      state.resume.awardForm = action.payload;
    },

    setResumeAwardFormAwardAgency(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const award = state.resume.awardForm[index];
      if (award instanceof Object && award !== null) {
        award.awardAgency = value;
      }
    },
    setResumeAwardFormAwardDay(state, action: PayloadAction<IndexActionPayloadTypeDate>) {
      const { index, value } = action.payload;
      const award = state.resume.awardForm[index];
      if (award instanceof Object && award !== null) {
        award.awardDay = value;
      }
    },
    setResumeAwardFormAwardName(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const award = state.resume.awardForm[index];
      if (award instanceof Object && award !== null) {
        award.awardName = value;
      }
    },
    setResumeAwardFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { index, value } = action.payload;
      const award = state.resume.awardForm[index];
      if (award instanceof Object && award !== null) {
        award.description = value;
      }
    },
    // setResumeAwardFormDescriptionList(state, action: PayloadAction<IndexActionPayloadListType>) {
    //   const { index, values } = action.payload;
    //   const award = state.resume.awardForm[index];
    //   if (award instanceof Object && award !== null) {
    //     award.description = values;
    //   }
    // },

    //** 자기소개 폼 */
    setResumeIntroduceForm(state, action: PayloadAction<IntroduceFormType[]>) {
      state.resume.introduceForm = action.payload;
    },

    setResumeIntroduceFormTitle(state, action: PayloadAction<IndexActionPayloadType>) {
      const { index, value } = action.payload;
      const introduce = state.resume.introduceForm[index];
      if (introduce instanceof Object && introduce !== null) {
        introduce.title = value;
      }
    },
    setResumeIntroduceFormDescription(state, action: PayloadAction<DescriptionPayloadType>) {
      const { index, value } = action.payload;
      const introduce = state.resume.introduceForm[index];
      if (introduce instanceof Object && introduce !== null) {
        introduce.description = value;
      }
    },

    setResumeAddForm(state, action: PayloadAction<FormListNameType>) {
      const formName = action.payload;
      const newForm = FormList[formName];
      const oldForm = state.resume[formName];
      if (oldForm?.length > 0) {
        state.resume[formName] = [...oldForm, newForm as any];
      } else {
        if (newForm) state.resume[formName] = [newForm as any];
      }
    },
    setResumeDeleteFormForIndex(
      state,
      action: PayloadAction<{
        formName: FormListNameType;
        index: number;
      }>,
    ) {
      const { index, formName } = action.payload;
      const form = state.resume[formName];
      if (form.length > 0 && !!form[index]) {
        form.splice(index, 1);
      } else if (form.length > 0 && formName === 'portfolioForm') {
        form.splice(index, 1);
      }
    },

    setResumeDeleteForm(state, action: PayloadAction<FormListNameType>) {
      state.resume[action.payload] = [];
    },

    //** 항목 설정 */
    setResumeItemSetting(state, action: PayloadAction<{ formName: string; isVisible: boolean }>) {
      const { formName, isVisible } = action.payload;
      state.resume.itemSetting = state.resume.itemSetting.map(item => {
        if (item.formName === formName) return { ...item, isVisible };
        return item;
      });
    },
  },
});

export const resumeActions = { ...resume.actions };

export default resume;
