import { ProjectListItemType } from '#types/data.d';
import { AxiosResponse } from 'axios';
import axios from './apiClient';
import { WriteProjectCommentType, ProjectType } from '#types/data';
import { makeUrlQueries } from '@miniintern/utils';
import { ProjectQueries } from '#types/apiQueries';
import { fetchWrapper } from '#lib/fetchWrapper';

export const getProjectListAPI = (queries: ProjectQueries) =>
  axios.get<{ projects: ProjectListItemType[]; count: number }>(
    makeUrlQueries('/api/v2/projects', queries),
  );

export const fetchProjectList = async (queries: ProjectQueries, option: RequestInit) =>
  fetchWrapper<{ projects: ProjectListItemType[]; count: number }>(
    makeUrlQueries('/api/v2/projects', queries),
    { ...option },
  );

const projectApiKey = (id: string) => `/api/v2/projects/${id}`;

export const getProjectAPI = (id: string) => axios.get<ProjectType>(projectApiKey(id));

export const fetchProject = async (id: string, options?: RequestInit) =>
  fetchWrapper<ProjectType>(projectApiKey(id), { ...options });

export interface GetProjectCommentAPIType {
  projectId: number;
  limit?: string;
  page?: string;
}

export const getProjectComments = ({ projectId, limit, page }: GetProjectCommentAPIType) =>
  axios.get(makeUrlQueries(`/api/v2/projects/${projectId}/qnas`, { limit, page }));

export const writeProjectComment = ({
  body,
  parentId,
  isPrivate,
  projectId,
}: WriteProjectCommentType) =>
  axios.post(`/api/v2/projects/${projectId}/qnas`, { body, parentId, isPrivate });

export interface deleteProjectCommentAPIAPIType {
  projectId: number;
  commentId: number;
}

export const deleteProjectComment = ({ projectId, commentId }: deleteProjectCommentAPIAPIType) =>
  axios.delete(`api/v2/projects/${projectId}/qnas/${commentId}`);

export const getCompetency = (id: number) => axios.get(`api/v2/projects/competencies/${id}`);

export const getCompetencies = () => axios.get('api/v2/projects/competencies');

export const participateProject = ({ form, projectId }: any): Promise<AxiosResponse<any>> =>
  axios.post(`api/v2/projects/${projectId}/participations`, form);

//* 제안서 업로드하기
export const uploadSuggestion = (
  projectId: number,
  step: 'draft' | 'final',
  fileId: number,
  username: string,
  filename: string,
) =>
  axios.post(`api/v2/projects/${projectId}/proposals`, {
    description: `${username}의 ${step}제안서 - ${filename}`,
    step,
    attachmentId: fileId,
  });

//* 제안서 수정하기
export const editSuggestion = (
  projectId: number,
  ProposalId: number,
  step: 'draft' | 'final',
  username: string,
  filename: string,
  fileId: number,
) =>
  axios.put(`api/v2/projects/${projectId}/proposals/${ProposalId}`, {
    description: `${username}의 ${step}제안서 - ${filename}`,
    step,
    attachmentId: fileId,
  });

//* 프로젝트 참가신청 취소하기
export const cancelProject = ({
  projectId,
  participationId,
}: {
  projectId: number;
  participationId: number;
}) => axios.delete(`api/v2/projects/${projectId}/participations/${participationId}`);

export interface PostSubmitSurveyType {
  projectId: number;
  content: {
    qIndex: number;
    type: string;
    answer: number | (string | number)[] | string;
  }[];
}

//* 프로젝트 설문조사 제출하기
export const submitSurvey = ({ projectId, content }: PostSubmitSurveyType) =>
  axios.post(`api/v2/me/projects/${projectId}/survey`, { content });

//* 프로젝트 이력서 올리기
export const uploadResume = ({
  participationId,
  resumeId,
  title,
  filePath,
}: {
  participationId: number;
  resumeId: number;
  title: string;
  filePath: string;
}) => axios.patch(`/api/v2/me/participations/${participationId}`, { resumeId, title, filePath });

//* 미니인턴 개최 승인
export const approveProjectAPI = ({ projectId }: { projectId: number }) =>
  axios.patch(`/api/v2/admins/projects/${projectId}/status/active`);

//* 미니인턴 개최 거부
export const rejectProjectAPI = (projectId: number) =>
  axios.delete(`/api/v2/admins/projects/${projectId}`);

//* 미니인턴 참가자 추가 신청
export const participateAddParticipant = (projectId: number, form: any) =>
  axios.post(`/api/v2/admins/projects/${projectId}/participations`, form);
