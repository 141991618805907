import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import keys from 'lodash/keys';

import { Answer, ParticipateBasic, ParticipateEventType } from '#types/data';
import { Event } from '#types/event';

export const participateEventState: ParticipateEventType = {
  body: {
    address: null,
    admissionDate: null,
    answers: [],
    birthday: null,
    email: '',
    gender: null,
    graduationDate: null,
    major: '',
    militaryStatus: null,
    name: '',
    phone: '',
    schoolName: '',
    schoolLocation: '',
  },
  isApplyingEvent: false,
};

export const participateEvent = createSlice({
  name: 'participateEvent',
  initialState: participateEventState,
  reducers: {
    insertBasic: (state, action: PayloadAction<ParticipateBasic>) => {
      //intersection 공통 배열 추출
      const values = pick(action.payload, intersection(keys(state.body), keys(action.payload))); //expect ["ex","ex2"]
      state.body = { ...state.body, ...values };
      return state;
    },
    changeAnswer: (state, action: PayloadAction<{ index: number; answer: Answer }>) => {
      //장문협,단답형
      if (action.payload.index === -1) {
        //기존에없던 항목이라면
        state.body.answers.push(action.payload.answer);
        return state;
      }
      if (typeof action.payload.answer.answer === 'string') {
        state.body.answers[action.payload.index] = action.payload.answer;
        return state;
      }
      if (
        Array.isArray(action.payload.answer.answer) &&
        action.payload.answer.answer.length === 0
      ) {
        state.body.answers[action.payload.index] = action.payload.answer;
        return state;
      }
      if (state.body.answers === null || state.body.answers.length === 0) {
        state.body.answers = [];
        return state;
      }

      state.body.answers[action.payload.index] = action.payload.answer;
      return state;
    },
    insertAdditionalQuestion: (state, action: PayloadAction<Event>) => {
      const event = action.payload;
      state.body.answers = event.questions.additional.map(question => ({
        id: question.id,
        body: question.body,
        type: question.type,
      }));
    },
    initParticipateEvent: state => {
      state = participateEventState;
      return state;
    },
    setParticipateAnswers: (state, action: PayloadAction<Answer[]>) => {
      state.body.answers = action.payload;
      return state;
    },
    setIsApplyingEvent: (state, action: PayloadAction<boolean>) => {
      state.isApplyingEvent = action.payload;
    },
  },
});

export const participateEventActions = participateEvent.actions;

export default participateEvent.reducer;
