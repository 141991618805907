import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationState } from '#types/redux/paginationModule';

export const paginationState: PaginationState = {
  page: 1,
  lastPage: 1,
};

const pagination = createSlice({
  name: 'pagination',
  initialState: paginationState,
  reducers: {
    initialize: () => paginationState,
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLastPage: (state, action: PayloadAction<number>) => {
      state.lastPage = action.payload;
    },
  },
});

export const paginationActions = pagination.actions;

export default pagination.reducer;
