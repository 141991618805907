import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/navigation';
import palette from '@miniintern/styles/palette';
import CloseBtnSVG from '@svg/resume/close-button.svg';
import { coreActions } from '#modules/core';
import responsive from '@miniintern/styles/responsive';
import routes from '#constants/routes';

const Container = styled.div`
  width: 100%;
  height: 56px;
  background: linear-gradient(to right, #33cfff, ${palette.blue_500});

  display: flex;
  align-items: center;

  .email-authentication-header-content-wrapper {
    display: flex;
    margin: 0 auto;
    align-items: center;
    height: 100%;

    @media (max-width: ${responsive.small}) {
      margin: 0;
      margin-left: 20px;
    }

    .email-authentication-header-content {
      color: white;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 29px;
      display: flex;
      align-items: center;
      @media (max-width: ${responsive.small}) {
        font-size: 14px;
        line-height: 21px;
        width: 196px;
        height: auto;
        font-weight: 400;
      }

      @media (max-width: ${responsive.xxsmall}) {
        font-size: 13px;
        width: 170px;
      }
    }

    .email-authentication-header-link-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${palette.blue_500};
      width: 88px;
      height: 29px;
      border-radius: 17.5px;
      background-color: white;
      font-weight: 500;
      margin-left: 16px;
      cursor: pointer;

      @media (max-width: ${responsive.medium}) {
        width: 78px;
        height: 29px;
        font-size: 12px;
        line-height: 17px;
      }

      @media (max-width: ${responsive.xxsmall}) {
        margin-left: 5px;
      }
    }
  }

  svg {
    position: absolute;
    right: 22px;
    cursor: pointer;
    path {
      opacity: 1;
      fill: ${palette.gray_300};
    }
  }
`;

const EmailAuthenticationHeader = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const onClickEmailAuthenticationLinkButton = () => router.push(routes.emailAuthentication);
  const onClickCloseButton = () => dispatch(coreActions.setEmailAuthenticationHeader(false));

  return (
    <Container>
      <div className="email-authentication-header-content-wrapper">
        <span className="email-authentication-header-content">
          미니인턴 서비스를 제한 없이 이용하려면 이메일을 인증해 주세요.
        </span>
        <div
          role="button"
          className="email-authentication-header-link-button-wrapper"
          onClick={onClickEmailAuthenticationLinkButton}
        >
          이메일 인증
        </div>
      </div>
      <CloseBtnSVG onClick={onClickCloseButton} />
    </Container>
  );
};

export default EmailAuthenticationHeader;
