import { useRouter } from 'next/navigation';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useModal from '#lib/hooks/useModal';
import routes from '#constants/routes';
import PopupContainer from '#components/common/popupBox/PopupContainer';
import useGTM from '#lib/hooks/useGTM';

const ReleaseDormancyUserPopupBlock = styled(PopupContainer)`
  .release-dormancy-user-popup-description {
    font-size: 14px;
    text-align: center;
    margin-top: 25px;
  }
`;

const ReleaseDormancyUserPopup: React.FC = () => {
  const router = useRouter();
  const { closeModal } = useModal();
  const { pushGtmEvent } = useGTM();

  useEffect(() => {
    pushGtmEvent({ event: 'login_done_awake' });
  }, []);

  return (
    <ReleaseDormancyUserPopupBlock
      tag="휴면 회원 해제"
      title="계정이 휴면 회원 해제 처리되었습니다."
      cancelButton={{
        title: '취소',
      }}
      confirmButton={{
        title: '확인',
        onClick: () => {
          router.push(routes.meAccount);
          closeModal();
        },
      }}
    >
      <p className="release-dormancy-user-popup-description">
        개인정보 보호를 위해 오래된 비밀번호를 변경 후 사용해 주세요.
        <br />
        회원정보 변경 페이지로 이동하시겠어요?
      </p>
    </ReleaseDormancyUserPopupBlock>
  );
};

export default ReleaseDormancyUserPopup;
