'use client'; //!

import palette from '@miniintern/styles/palette';
import React from 'react';
import styled from 'styled-components';
import routes from '#constants/routes';
import { Button } from '@miniintern/ui';
import MePcSidebarItem from './MePcSidebarItem';
import ResumeIconSVG from '@svg/me/snb/resume-sidebar-gray-icon.svg';
import ResumeBlueIconSVG from '@svg/me/snb/resume-sidebar-blue-icon.svg';
import useMeMenu from './useMeMenu';
import { usePathname } from 'next/navigation'; //!
import Link from 'next/link';

const MePcSidebarBlock = styled.ul`
  min-width: 282px;
  min-height: 100vh;
  padding: 40px 15px;
  border-right: 1px solid ${palette.gray_300};

  .documents-link {
    display: flex;
    gap: 4px;
    margin-bottom: 20px;
  }
`;

type MePcSidebarProps = {
  className: string;
};

const MePcSidebar: React.FC<MePcSidebarProps> = ({ className }) => {
  const pathname = usePathname(); //!
  const { meMenuList } = useMeMenu();

  const isMeDocuments = pathname === routes.meDocuments;

  return (
    <MePcSidebarBlock className={className}>
      <li>
        <Link href={routes.meDocuments}>
          <Button
            buttonType="line"
            size="xLarge"
            color={isMeDocuments ? 'blue_500' : 'black'}
            className="documents-link"
            data-gtm-label="my_click_menu_docs"
            leftIcon={isMeDocuments ? <ResumeBlueIconSVG /> : <ResumeIconSVG />}
          >
            지원서류 관리
          </Button>
        </Link>
      </li>
      {meMenuList.map((menu, i) => {
        if (menu.value === 'documents') return null; //!
        return <MePcSidebarItem menu={menu} key={i} />;
      })}
    </MePcSidebarBlock>
  );
};

export default MePcSidebar;
