'use client';
import { createGlobalStyle } from 'styled-components';
import GlobalStyle from '@miniintern/styles/GlobalStyle';
import responsive from '@miniintern/styles/responsive';

const originalWarn = console.warn;
const originalError = console.error;
console.warn = (...args) => {
  if (/styled-components/.test(args[0])) {
    return;
  }
  originalWarn.call(console, ...args);
};
console.error = (...args) => {
  if (/React does not recognize the .* prop on a DOM element./.test(args[0])) {
    return;
  }
  if (/Received .* for a non-boolean attribute .*/.test(args[0])) {
    return;
  }
  originalError.call(console, ...args);
};

const CustomGlobalStyle = createGlobalStyle`
    ${GlobalStyle}
    @font-face {
    font-style: normal;
    font-family: GmarketSans;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/GmarketSans-Ragular.woff') format('woff');
    }

    @font-face {
    font-style: normal;
    font-family: GmarketSans;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/GmarketSans-Bold.woff') format('woff');
    }

    .visible-on-mobile {
    display: none;
    @media (max-width: ${responsive.medium}) {
      display: block;
      }
    }
 
  .visible-on-pc {
    display: block;
    @media (max-width: ${responsive.medium}) {
      display: none;
    }
  }
`;
export default CustomGlobalStyle;
