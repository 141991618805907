'use client';

import palette from '@miniintern/styles/palette';
import Link from 'next/link';
import React, {
  useEffect, //!
  useState,
} from 'react';
import DownArrowIconSVG from '@svg/me/snb/down-arrow-icon.svg';
import { usePathname } from 'next/navigation'; //!
import styled, { css } from 'styled-components';
import { MeMenuType } from './useMeMenu';
import routes from '#constants/routes';

const MePcSidebarItemBlock = styled.li<{
  isActive: boolean;
  isOpenSubMenu: boolean;
  hasSubMenu: boolean;
}>`
  .sidebar-menu-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    position: relative;

    .sidebar-menu-list-inner-box {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 8px 8px 20px;
      color: ${palette.black};
    }

    .sidebar-menu-list-dropdown-arrow-svg {
      transition: transform 0.25s linear;

      ${props =>
        props.isOpenSubMenu &&
        css`
          transform: rotate(-180deg);

          g {
            path:nth-child(2) {
              fill: ${palette.blue_500};
            }
          }
        `}
    }

    .sidebar-menu-list-dropdown-button {
      display: flex;
      align-items: center;
      position: absolute;
      right: 8px;
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;

      &:hover {
        g {
          path:nth-child(2) {
            fill: ${palette.blue_500};
          }
        }
      }
    }

    ${({ isActive, hasSubMenu }) =>
      isActive &&
      css`
        .sidebar-menu-list-inner-box {
          color: ${palette.blue_500};
          font-weight: 500;
          background-color: ${!hasSubMenu && palette.blue_100};
          border-radius: 6px;
        }
      `}

    &:hover {
      background-color: ${palette.gray_50};
      border-radius: 6px;
    }
  }

  .sidebar-menu-sub-list-box {
    margin-bottom: 4px;
  }
`;

const MePcSidebarSubItem = styled.li<{ isFocusedSubMenu: boolean; isDisabled: boolean }>`
  margin-left: 20px;

  a,
  li {
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${palette.gray_500};
    padding: 6px 32px;
  }

  &:hover {
    background-color: ${palette.gray_50};
    border-radius: 6px;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      padding: 6px 32px;
      color: ${palette.gray_300};
    `}

  ${({ isFocusedSubMenu }) =>
    isFocusedSubMenu &&
    css`
      a {
        color: ${palette.blue_500};
        background-color: ${palette.blue_100};
        border-radius: 6px;
      }
    `}
`;

const gtmAccordionTable = {
  [routes.meApplications]: 'my_click_accordion_application',
  [routes.meSkillupCourse]: 'my_click_accordion_skillup',
  [routes.meHappyfolio()]: 'my_click_accordion_happyfolio',
};
const gtmMenuTable = {
  [routes.meApplications]: 'my_click_menu_application',
  [routes.meProject]: 'my_click_menu_miniintern',
  [routes.meSkillupCourse]: 'my_click_menu_skillup',
  [routes.meEvent]: 'my_click_menu_mclass',
  [routes.meHappyfolio()]: 'my_click_menu_happyfolio',
};
const gtmSubMenuTable = {
  [routes.meSkillupCourse]: 'my_click_menu_skillup_course',
  [routes.meSkillupPayHistory]: 'my_click_menu_skillup_payhistory',
  [routes.meApplications]: 'my_click_menu_application_status',
  [routes.meHeadhunting]: 'my_click_menu_application_headhunting',
  [routes.meHappyfolio()]: 'my_click_menu_happyfolio_manage',
  [routes.meHappyfolioHost()]: 'my_click_menu_happyfolio_hostmanage',
};

type MePcSidebarItemProps = {
  menu: MeMenuType;
};

const MePcSidebarItem: React.FC<MePcSidebarItemProps> = ({ menu }) => {
  const pathname = usePathname(); //!
  const currentPathArr = pathname ? pathname.split('/').slice(1, 4) : []; //!
  const isActive = menu.value === currentPathArr[1];
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(isActive);

  useEffect(() => {
    //!
    if (isActive) {
      setIsOpenSubMenu(true);
    }
  }, [isActive]);
  return (
    <MePcSidebarItemBlock
      isActive={isActive}
      isOpenSubMenu={isOpenSubMenu}
      hasSubMenu={!!menu.subMenu}
    >
      <div className="sidebar-menu-list-item">
        <Link
          href={menu.link}
          passHref
          className="sidebar-menu-list-inner-box"
          data-gtm-label={gtmMenuTable[menu.link]}
        >
          {isActive ? menu.activatedIconSVG : menu.inactivatedIconSVG}
          {menu.title}
        </Link>
        {menu.subMenu && (
          <button
            className="sidebar-menu-list-dropdown-button"
            onClick={() => setIsOpenSubMenu(!isOpenSubMenu)}
            data-gtm-label={gtmAccordionTable[menu.link]}
          >
            <DownArrowIconSVG className="sidebar-menu-list-dropdown-arrow-svg" />
          </button>
        )}
      </div>
      {menu.subMenu && isOpenSubMenu && (
        <ul className="sidebar-menu-sub-list-box">
          {menu.subMenu.map((sub, i) => {
            const isFocusedSubMenu = //!
              pathname !== null &&
              currentPathArr.slice(-1)[0] === sub.link.split('/').slice(-1)[0].split('?')[0] &&
              pathname.includes(sub.link);

            return (
              <MePcSidebarSubItem
                key={i}
                isFocusedSubMenu={!!isFocusedSubMenu} //!
                isDisabled={sub.disabled}
              >
                {!sub.disabled ? (
                  <Link
                    href={!sub.disabled ? sub.link : ''}
                    passHref
                    data-gtm-label={gtmSubMenuTable[sub.link]}
                  >
                    {sub.title}
                  </Link>
                ) : (
                  sub.title
                )}
              </MePcSidebarSubItem>
            );
          })}
        </ul>
      )}
    </MePcSidebarItemBlock>
  );
};

export default MePcSidebarItem;
