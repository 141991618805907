import axios from './apiClient';
import { makeUrlQueries } from '@miniintern/utils';
import { ParticipateEventBody } from '#types/data';
import { EventQueries } from '#types/apiQueries';
import { Event, EventResponseType, EventParticipation } from '#types/event';
import {
  EventItemType,
  PostRegisterEventType,
} from '../../(pages)/me/event/host/register/_components/defaultEventData';
import { fetchWrapper } from '#lib/fetchWrapper';
import { sendErrorToSentry } from '#lib/error';
import {
  HostManagementEventItemUpdateParticipantsRefundRequest,
  HostManagementEventItemUpdateParticipantsRequest,
} from '#types/host';

export const getEventsApiKey = (queries: EventQueries) => makeUrlQueries('/api/v3/events', queries);

//* M클래스 전체 리스트 조회
export const getEvents = (queries: EventQueries) =>
  axios.get<EventResponseType>(getEventsApiKey(queries));

export const fetchEvents = async (queries: EventQueries) => {
  try {
    const res = await fetchWrapper<EventResponseType>(getEventsApiKey(queries));
    return res;
  } catch (error) {
    sendErrorToSentry(error);
    return {
      events: [],
      count: 0,
    };
  }
};

//* M클래스 단일 리스트 조회
export const getSingleEventApiKey = (id: string | string[]) => `/api/v3/events/${id}`;

export const getSingleEventAPI = (id: string | string[]) =>
  axios.get<Event>(getSingleEventApiKey(id));

export const fetchSingleEventAPI = (id: string | string[], options?: RequestInit) =>
  fetchWrapper<Event>(getSingleEventApiKey(id), { ...options });

//* M클래스 신청 취소하기
export const cancelEvent = (id: number, participationId: number, cancelReason: string) =>
  axios.patch(`/api/v2/me/events/${id}/participations/${participationId}`, { cancelReason });

/** M클래스 개최 승인하기 */
export const approveEventAPI = (id: number) => axios.post(`/api/v3/admins/events/${id}/approve`);

/** M클래스 승인 반려 */
export const pendingEventAPI = (eventId: number, body: { editRequestComment: string }) =>
  axios.patch(`/api/v3/admins/events/${eventId}/pending`, body);

type AddEventParticipationAPIBody = ParticipateEventBody & {
  userId: number;
};

//* 참가자 추가하기
export const addEventParticipationAPI = (eventId: number, body: AddEventParticipationAPIBody) =>
  axios.post(`/api/v3/admins/events/${eventId}/participations`, body);

interface PayEventAPIBody extends ParticipateEventBody {
  payValidateData: {
    receiptId: string;
    orderId: string;
    productPrice: number;
  };
}

//* M클레스 유료 참가신청
export const payEventAPI = (eventId: number, body: PayEventAPIBody) =>
  axios.post(`/api/v3/events/${eventId}/participations`, body);

//* M클래스 무료 참가신청 하기
export const participateEventAPI = (eventId: number, body: ParticipateEventBody) =>
  axios.post<Event & { participation: EventParticipation }>(
    `/api/v3/events/${eventId}/participations`,
    body,
  );

//* 마이커리어 > 이전 M클래스 리스트 불러오기
export const getLoadPreviousListAPI = () =>
  axios.get<EventItemType[]>(`/api/v3/me/hosts/events/search`);

//* 마이커리어 > M클래스 단일 조회
export const getLoadPreviousEventAPI = (eventId: number) =>
  axios.get(`/api/v3/me/hosts/events/${eventId}/before`);

//* 마이커리어 > M클래스 등록
export const registerEventAPI = (eventInfo: PostRegisterEventType) =>
  axios.post(`/api/v3/me/hosts/events`, eventInfo);

//* 마이커리어 > M클래스 수정
export const editEventAPI = ({
  eventId,
  eventInfo,
}: {
  eventId: number;
  eventInfo: PostRegisterEventType;
}) => axios.put(`/api/v3/me/hosts/events/${eventId}`, eventInfo);

//* 마이커리어 > M클래스 참가자 상태 업데이트
export const updateEventParticipantsAPI = (
  eventId: number,
  participations: HostManagementEventItemUpdateParticipantsRequest[],
) =>
  axios.patch(`/api/v3/me/hosts/events/${eventId}/participations`, {
    participations: participations.map(participation => ({
      id: participation.id,
      status: participation.status,
    })),
  });

//* 마이커리어 > M클래스 참가자 환불 처리
export const updateEventParticipantsRefundAPI = (
  eventId: number,
  participations: HostManagementEventItemUpdateParticipantsRefundRequest[],
) =>
  axios.patch(`/api/v3/me/hosts/events/${eventId}/participations/refund`, {
    participations: participations.map(participation => ({
      id: participation.id,
    })),
  });

//* 마이커리어 > M클래스 참가자 엑셀 다운로드
export const downloadEventParticipantsExcelAPI = (eventId: number, participationIds: number[]) =>
  axios.post(
    `/api/v3/me/hosts/events/${eventId}/participations/excel`,
    { participationIds },
    { responseType: 'blob' },
  );
