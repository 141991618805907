import createSagaMiddleware, { END, Task } from 'redux-saga';
import { combineReducers, Store, configureStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { all, fork } from 'redux-saga/effects';
import core from './core';
import auth, { authSaga } from './auth';
import project, { projectSaga } from './project';
import event, { eventSaga } from './event';
import pagination from './pagination';
import participateEvent from './participateEvent';
import participateBasic from './participateBasic';
import resume from './resume';
import me, { meSaga } from './me';
import recruitment, { recruitmentSaga } from './recruitment';
import happyfolioReducer, { happyfolioSaga } from './happyfolio';
import magazine from './magazine';
import skillup, { skillupSaga } from './skillup';

export const rootReducer = combineReducers({
  core,
  auth,
  project,
  event: event.reducer,
  pagination,
  participateEvent,
  participateBasic,
  resume: resume.reducer,
  magazine: magazine.reducer,
  me,
  recruitment,
  happyfolio: happyfolioReducer,
  skillup: skillup.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const enhancedReducer = (state: RootState, action: any) => {
  if (action.type === HYDRATE) {
    const result = {
      ...state, // 이전 state를 사용
      ...action.payload, // 업데이트 됱 데이터 주입
    };
    result.core = state.core;
    //* 리스트페이지로 돌아올 경우 이전 데이터를 가지고 있도록 수정
    result.recruitment.recruitmentList = state.recruitment.recruitmentList;
    result.recruitment.recruitmentFilterHistory = state.recruitment.recruitmentFilterHistory;
    result.event.eventList = state.event.eventList;
    result.project.projectList = state.project.projectList;
    result.skillup.list = state.skillup.list;
    result.happyfolio.happyfolioHome.themedHappyfolio =
      state.happyfolio.happyfolioHome.themedHappyfolio;
    if (state.happyfolio.happyfolioHome.themedHappyfolioList.happyfolios.length > 0) {
      result.happyfolio.happyfolioHome.themedHappyfolioList.happyfolios =
        state.happyfolio.happyfolioHome.themedHappyfolioList.happyfolios;
    }

    return result;
  }
  return rootReducer(state, action);
};

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(projectSaga),
    fork(meSaga),
    fork(eventSaga),
    fork(skillupSaga),
    fork(recruitmentSaga),
    fork(happyfolioSaga),
  ]);
}
export interface SagaStore extends Store {
  sagaTask?: Task;
  runSaga: () => void;
  stopSaga: () => void;
  execSagaTasks: (isServer: any, tasks: any) => Promise<void>;
}

export const initStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: enhancedReducer,
    devTools: process.env.CLIENT_URL !== 'https://miniintern.com',
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(sagaMiddleware),
  });

  (store as SagaStore).runSaga = () => {
    if ((store as SagaStore).sagaTask) {
      return;
    }
    (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);
  };

  (store as SagaStore).stopSaga = async () => {
    if (!(store as SagaStore).sagaTask) {
      return;
    }
    (store as SagaStore).dispatch(END);
    await (store as SagaStore).sagaTask?.toPromise();
  };

  (store as SagaStore).execSagaTasks = async (isServer, tasks) => {
    (store as SagaStore).runSaga();
    tasks((store as SagaStore).dispatch);

    if (isServer) {
      await (store as SagaStore).stopSaga();
    }

    // if (!isServer) {
    //   (store as SagaStore).runSaga();
    // }
  };

  (store as SagaStore).runSaga();
  return store;
};

export type AppStore = ReturnType<typeof initStore>;

export const wrapper = createWrapper(initStore, {
  serializeState: state => JSON.stringify(state),
  deserializeState: state => JSON.parse(state),
});

export default wrapper;
