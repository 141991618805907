'use client';

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT_MOBILE, HEADER_HEIGHT_PC } from '#styles/headerHeight';
import palette from '@miniintern/styles/palette';
import responsive from '@miniintern/styles/responsive';
import zIndexes from '#styles/zIndexes';

const ListPageStickyHeaderBlock = styled.div<{ isSticky: boolean }>`
  position: sticky;
  top: -1px;
  margin: -${HEADER_HEIGHT_PC}px 0 0;
  padding: ${HEADER_HEIGHT_PC}px 0 0;
  transition: box-shadow 0.3s ease-in-out;
  background-color: ${palette.white};
  z-index: ${zIndexes.listPageStickyHeader};
  box-shadow: ${props => (props.isSticky ? ' 0 4px 8px 4px rgba(0, 0, 0, 0.1)' : 'none')};

  @media (max-width: ${responsive.medium}) {
    margin: -${HEADER_HEIGHT_MOBILE + 2}px 0 0;
    padding: ${HEADER_HEIGHT_MOBILE}px 0 0;
  }
`;

type ListPageStickyHeaderProps = {
  className?: string;
  children?: React.ReactNode;
};

const ListPageStickyHeader: React.FC<ListPageStickyHeaderProps> = ({ children, className }) => {
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!headerRef.current) return;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1);
      },
      { threshold: [1] },
    );

    observer.observe(headerRef.current);

    return () => observer && observer.disconnect();
  }, []);

  return (
    <ListPageStickyHeaderBlock ref={headerRef} isSticky={isSticky} className={className}>
      {children}
    </ListPageStickyHeaderBlock>
  );
};

export default ListPageStickyHeader;
