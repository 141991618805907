import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { EventQueries } from '#types/apiQueries';
import { Event, EventParticipation, EventResponseType } from '#types/event';
import { getEvents, getSingleEventAPI } from '#lib/api/event';
import { sendErrorToSentry } from '#lib/error';

//* participation은 마이 커리어 일때만 있음
export type EventState = {
  eventList: {
    isLoading: boolean;
    events: (Event & { participation?: EventParticipation })[];
    count: number;
    queries: EventQueries | null;
  };
  eventListForMainPage: Event[];
  eventDetail: Event | null;
  //*추가할 신청자아이디
  willAddUserId: number | null;
  //* 신청방지용
  applyReady: boolean;
  isLoading: boolean;
};

const initialState: EventState = {
  eventList: {
    isLoading: true,
    events: [],
    count: 0,
    queries: null,
  },
  eventListForMainPage: [],
  eventDetail: null,
  willAddUserId: null,
  applyReady: false,
  isLoading: false,
};

const event = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventList_Request(state, action: PayloadAction<EventQueries>) {
      state.eventList.isLoading = true;
      if (action.payload.page === '1') {
        state.eventList.count = initialState.eventList.count;
        state.eventList.events = initialState.eventList.events;
      }
      state.eventList.queries = action.payload;
    },

    setEventList_Success(state, action: PayloadAction<EventResponseType>) {
      state.eventList.isLoading = false;
      state.eventList.count = action.payload.count;
      state.eventList.events = [...state.eventList.events, ...action.payload.events];
    },

    setEventList_Failure(state) {
      state.eventList.isLoading = false;
      state.eventList.queries = null;
    },

    //신청한 M클래스 리스트 (임시)
    setAppliedEvents(state, action: PayloadAction<Event[]>) {
      state.eventList.events = action.payload;
    },

    initEventDetail(state) {
      state.eventDetail = initialState.eventDetail;
    },

    getEventDetail_Request(state, _action: PayloadAction<string>) {
      state.isLoading = true;
    },

    getEventDetail_Success(state, action: PayloadAction<Event>) {
      state.eventDetail = action.payload;
      state.isLoading = false;
    },
    getEventDetail_Failure(state) {
      state.isLoading = false;
    },

    startPaymentProcess(state, _action: PayloadAction<undefined>) {
      if (!state.eventDetail) return;
      state.eventDetail.isBeingPayment = true;
    },

    endPaymentProcess(state, _action: PayloadAction<undefined>) {
      if (!state.eventDetail) return;
      state.eventDetail.isBeingPayment = false;
    },

    //* 추가할 신청자 id 변경하기
    setAddUserId(state, action: PayloadAction<number>) {
      state.willAddUserId = action.payload;
    },

    //* 추가할 신청자 id 변경하기
    changeApplyReady(state, action: PayloadAction<boolean>) {
      state.applyReady = action.payload;
    },
  },
});

function* watchSetEventList() {
  const { setEventList_Request, setEventList_Success, setEventList_Failure } = event.actions;

  yield takeLatest(setEventList_Request, function* (action) {
    try {
      const { data } = yield call(getEvents, action.payload);
      yield put(setEventList_Success(data));
    } catch (err) {
      sendErrorToSentry(err);
      yield put(setEventList_Failure());
    }
  });
}

function* watchGetEventDetail() {
  const { getEventDetail_Request, getEventDetail_Success, getEventDetail_Failure } = event.actions;
  yield takeEvery(getEventDetail_Request, function* (action) {
    try {
      const { data } = yield call(getSingleEventAPI, action.payload);
      yield put(getEventDetail_Success(data));
    } catch (err) {
      sendErrorToSentry(err);
      yield put(getEventDetail_Failure());
    }
  });
}

export function* eventSaga() {
  yield all([fork(watchSetEventList), fork(watchGetEventDetail)]);
}

export const eventActions = { ...event.actions };

export default event;
