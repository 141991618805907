import { MagazineContentResponseType } from '#types/magazine';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MagazineState = {
  contentList: MagazineContentResponseType[];
  isLoading: boolean;
};

const initialState: MagazineState = {
  contentList: [],
  isLoading: true,
};

const magazine = createSlice({
  name: 'magazine',
  initialState,
  reducers: {
    //* 매거진 컨텐츠 전체 리스트
    setMagazine(state, action: PayloadAction<MagazineContentResponseType[]>) {
      state.contentList = action.payload;
      state.isLoading = false;
    },
  },
});

export const magazineActions = { ...magazine.actions };

export default magazine;
