import { SkillupListQueries } from '#types/apiQueries';
import axios from '#lib/api/apiClient';
import { makeUrlQueries } from '@miniintern/utils';
import { CourseDetail, SkillupList, SkillupCourse, SkillupQnaType } from '#types/skillup';
import { PayValidateType } from '#types/pay';
import { fetchWrapper } from '#lib/fetchWrapper';

export const getSkillupApiKey = (queries: SkillupListQueries) =>
  makeUrlQueries('/api/v2/skillup/courses', queries);

export const getSkillupListAPI = (queries: SkillupListQueries) =>
  axios.get<SkillupList>(getSkillupApiKey(queries));

export const fetchSkillupListAPI = async (queries: SkillupListQueries, option: RequestInit = {}) =>
  fetchWrapper<SkillupList>(getSkillupApiKey(queries), option);

// ==================== 🚀 구분선 🚀 ====================

export const getSkillupDetailApiKey = (skillupId: string | number) =>
  `/api/v2/skillup/courses/${skillupId}`;
export const getSkillupDetail = (skillupId: string | number) =>
  axios.get<CourseDetail>(getSkillupDetailApiKey(skillupId));
export const fetchSkillupDetail = (skillupId: string | number, options: RequestInit = {}) =>
  fetchWrapper<CourseDetail>(getSkillupDetailApiKey(skillupId), options);

// ==================== 🚀 구분선 🚀 ====================

export const getSkillupCourseApiKey = (skillupId: string | number) =>
  `/api/v2/skillup/courses/${skillupId}/lecture`;
export const getSkillupCourse = ({ id }: { id: number }) =>
  axios.get<SkillupCourse>(getSkillupCourseApiKey(id));
export const fetchSkillupCourse = (skillupId: string | number, options: RequestInit = {}) =>
  fetchWrapper<SkillupCourse>(getSkillupCourseApiKey(skillupId), options);

// ==================== 🚀 구분선 🚀 ====================

interface PatchLecturePlayTimeArgs {
  courseId: number;
  lectureId: number;
  playedTime: number;
}

// 강의 재생 시간 업데이트
export const patchLecturePlayTime = ({
  courseId,
  lectureId,
  playedTime,
}: PatchLecturePlayTimeArgs) =>
  axios.patch(`/api/v2/skillup/courses/${courseId}/lecture/${lectureId}/played-time`, {
    playedTime,
  });

interface PostSkillupAssignmentArgs {
  courseId: number;
  lectureId: number;
  userAssignmentFileId: number;
}

// 과제 제출
export const postSkillupAssignment = ({
  courseId,
  lectureId,
  userAssignmentFileId,
}: PostSkillupAssignmentArgs) =>
  axios.post(`/api/v2/skillup/courses/${courseId}/lecture/${lectureId}/assignment`, {
    userAssignmentFileId,
  });

interface PatchSkillupCompleteArgs {
  courseId: number;
  lectureId: number;
}

// 강의 수강 완료
export const patchSkillupComplete = ({ courseId, lectureId }: PatchSkillupCompleteArgs) =>
  axios.patch(`/api/v2/skillup/courses/${courseId}/lecture/${lectureId}/completed`);

interface PatchSkillupAssignmentDownloadHistoryArgs {
  courseId: number;
  lectureId: number;
}

// 과제 다운로드 히스토리 업데이트
export const patchSkillupAssignmentDownloadHistory = ({
  courseId,
  lectureId,
}: PatchSkillupAssignmentDownloadHistoryArgs) =>
  axios.patch(`/api/v2/skillup/courses/${courseId}/lecture/${lectureId}/download-history`);

// interface PostFeedbackPaymentArgs {
//   orderId: string;
//   payId: number;
//   participantAssignmentId: number;
// }

interface PatchSkillupFeedbackDownloadHistoryArgs {
  participantAssignmentId: number;
  courseId: number;
  lectureId: number;
}

export const patchSkillupFeedbackDownloadHistory = ({
  participantAssignmentId,
  courseId,
  lectureId,
}: PatchSkillupFeedbackDownloadHistoryArgs) =>
  axios.patch(`/api/v2/skillup/courses/${courseId}/lecture/${lectureId}/feedback-history`, {
    participantAssignmentId,
  });

interface paySkillupCourseAPIBodyType extends PayValidateType {}

export const paySkillupCourseAPI = (courseId: number, body: paySkillupCourseAPIBodyType) =>
  axios.post(`api/v3/skillup/courses/${courseId}/enrollment`, body);

export const enrollSkillupCourseAPI = (courseId: number) =>
  axios.post(`api/v3/skillup/courses/${courseId}/enrollment`);

interface PaySkillupFeedbackAPIBodyType extends Omit<PayValidateType, 'phone'> {}

interface PostFeedbackEnrollmentResponse {
  participantAssignmentId: number;
}

export interface SkillupQnaParamsType {
  courseId: number;
  qnaId: number;
}

export interface PostSkillupQnaBodyType extends Pick<SkillupQnaType, 'content' | 'title'> {}
export interface PostSkillupQnaResponseType {
  id: number;
}

export const postSkillupQna = (
  params: Omit<SkillupQnaParamsType, 'qnaId'>,
  body: PostSkillupQnaBodyType,
) =>
  axios.post<PostSkillupQnaResponseType>(
    `/api/v2/skillup/courses/${params.courseId}/lecture/qna`,
    body,
  );

export interface PatchSkillupQnaBodyType extends Partial<PostSkillupQnaBodyType> {}

export const patchSkillupQna = (params: SkillupQnaParamsType, body: PatchSkillupQnaBodyType) =>
  axios.patch(
    `/api/v2/skillup/courses/${params.courseId}/lecture/qna/${params.qnaId}/content`,
    body,
  );

export const deleteSkillupQna = (params: SkillupQnaParamsType) =>
  axios.delete(`/api/v2/skillup/courses/${params.courseId}/lecture/qna/${params.qnaId}`);

export const checkSkillupQna = (params: SkillupQnaParamsType) =>
  axios.patch(`/api/v2/skillup/courses/${params.courseId}/lecture/qna/${params.qnaId}/check`);

export const paySkillupFeedbackAPI = (
  lectureAssignmentId: number,
  body: PaySkillupFeedbackAPIBodyType,
) =>
  axios.post<PostFeedbackEnrollmentResponse>(
    `/api/v3/skillup/courses/feedback/${lectureAssignmentId}`,
    body,
  );

export const applySkillupFeedbackAPI = (lectureAssignmentId: number) =>
  axios.post<PostFeedbackEnrollmentResponse>(
    `/api/v3/skillup/courses/feedback/${lectureAssignmentId}`,
  );
