import { ProjectQueries } from '#types/apiQueries.d';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, all, fork, takeLatest, select } from 'redux-saga/effects';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import isPast from 'date-fns/isPast';
import {
  WriteProjectCommentType,
  ProjectType,
  CompetencyType,
  ProjectCommentType,
} from '#types/data';
import { GetProjectCommentAPIType } from '#lib/api/project';
import * as projectAPI from '#lib/api/project';
import { coreActions } from './core';
import {
  checkCompetencyArg,
  deleteProjectCommentArg,
  getCompetenciesSuccessArg,
  getProjectCommentSuccessArg,
  getProjectListSuccessArg,
  ProjectState,
} from '#types/redux/projectModule';
import { sendErrorToSentry } from '#lib/error';
import { RootState } from '.';

export const projectState: ProjectState = {
  projectList: {
    projects: [],
    count: 0,
    isLoading: true,
    queries: null,
  },
  projectListForMainPage: [],
  project: null,
  competency: {
    id: 0,
    name: '',
    description: '',
    categoryId: '',
    unused: false,
    createdAt: null,
    updatedAt: null,
    category: '',
    similarCompetencies: [],
  },
  affixHeader: false,
  competencies: {},
  comment: {
    commentList: [],
    count: 0,
    commentPage: '1',
  },

  selectMode: 'off',
  loading: false,
  projectPlan: '',
  applyForCompany: false,
  willAddUserId: null,
};

const project = createSlice({
  name: 'project',
  initialState: projectState,
  reducers: {
    initializeCompetency: state => {
      state.competency = projectState.competency;
    },

    deleteCanceledProject: (state, action: PayloadAction<number>) => {
      //focusedProject 제거하기
      state.projectList.projects.splice(action.payload, 1);
    },
    getProjectList: (state, action: PayloadAction<ProjectQueries>) => {
      state.projectList.isLoading = true;
      if (action.payload.page === '1') {
        state.projectList.count = projectState.projectList.count;
        state.projectList.projects = projectState.projectList.projects;
      }
      state.projectList.queries = action.payload;
    },
    getProjectListSuccess: (state, action: PayloadAction<getProjectListSuccessArg>) => {
      state.projectList.isLoading = false;
      state.projectList.projects = [...state.projectList.projects, ...action.payload.projects];
      state.projectList.count = action.payload.count;
    },
    getProject: (state, _action: PayloadAction<string | string[]>) => {
      state.loading = true;
    },
    getProjectSuccess: (state, action: PayloadAction<{ data: ProjectType }>) => {
      const { data } = action.payload;
      const { stage, resultDate } = data;
      const isDelayed = stage === 'evaluating' && isPast(new Date(resultDate!));
      state.project = { ...data, isDelayed };
      state.loading = false;
    },
    getProjectComment: (_state, _action: PayloadAction<GetProjectCommentAPIType>) => {},
    getProjectCommentSuccess: (state, action: PayloadAction<getProjectCommentSuccessArg>) => {
      state.comment.commentList = action.payload.qnas;
      state.comment.count = action.payload.count;
      state.comment.commentPage = action.payload.commentPage;
    },
    getProjectCommentFailure: () => {},
    writeProjectComment: (_state, _action: PayloadAction<WriteProjectCommentType>) => {},
    writeProjectCommentSuccess: (_state, _action: PayloadAction<ProjectCommentType>) => {},
    deleteProjectComment: (_state, _action: PayloadAction<deleteProjectCommentArg>) => {},
    getCompetency: (_state, _action: PayloadAction<number>) => {},
    getCompetencySuccess: (state, action: PayloadAction<CompetencyType>) => {
      state.competency = action.payload;
    },
    getCompetencies: (_state, _action: PayloadAction<number>) => {},
    getCompetenciesSuccess: (state, action: PayloadAction<getCompetenciesSuccessArg>) => {
      const { contencies, profile } = action.payload;
      const profileCompetency = profile.competencies || [];
      const addCheckValue = contencies.map(compentency => {
        const checked =
          !isEmpty(profileCompetency) &&
          profileCompetency.filter(checkedCompetency => checkedCompetency.id === compentency.id)
            .length > 0;
        return { ...compentency, checked };
      });
      const result = groupBy(addCheckValue, 'categoryId'); // categoryId 기준으로 group화를 시켜서 객체를 만든다.
      state.competencies = result;
    },
    checkCompetency: (state, action: PayloadAction<checkCompetencyArg>) => {
      const { categoryId, checkedList } = action.payload;
      state.competencies[categoryId].map(competency => {
        const { id } = competency;
        if (checkedList.includes(id!)) {
          competency.checked = true;
        } else {
          competency.checked = false;
        }
        return competency;
      });
    },
    setAffixHeader: (state, action: PayloadAction<{ visible: boolean }>) => {
      const { visible } = action.payload;
      state.affixHeader = visible;
    },
    changeProjectPlan: (state, action: PayloadAction<string>) => {
      state.projectPlan = action.payload;
    },
    changeApplyForCompany: (state, action: PayloadAction<boolean>) => {
      state.applyForCompany = action.payload;
    },
    setWillAddUserId: (state, action: PayloadAction<number>) => {
      state.willAddUserId = action.payload;
    },
  },
});

export const projectActions = project.actions;

export function* watchGetProject() {
  yield takeLatest(projectActions.getProject.type, function* (action: PayloadAction<string>) {
    try {
      const { data } = yield call(projectAPI.getProjectAPI, action.payload);
      yield put({
        type: projectActions.getProjectSuccess.type,
        payload: { data },
      });
    } catch (err) {
      if (err) {
        sendErrorToSentry(err);
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: err.statusText,
            type: 'error',
            fn: 'toHome',
          },
        });
      }
    }
  });
}

export function* watchGetProjectList() {
  yield takeLatest(
    projectActions.getProjectList.type,
    function* (action: PayloadAction<ProjectQueries>) {
      try {
        const { data: projectList } = yield call(projectAPI.getProjectListAPI, {
          ...action.payload,
        });
        yield put({
          type: projectActions.getProjectListSuccess.type,
          payload: projectList,
        });
      } catch (err) {
        if (err) {
          sendErrorToSentry(err);
        }
      }
    },
  );
}

export function* watchGetProjectComment() {
  yield takeLatest(
    projectActions.getProjectComment.type,
    function* (action: PayloadAction<GetProjectCommentAPIType>) {
      try {
        const { data } = yield call(projectAPI.getProjectComments, action.payload);
        yield put({
          type: projectActions.getProjectCommentSuccess.type,
          payload: { ...data, commentPage: action.payload.page },
        });
      } catch (err) {
        sendErrorToSentry(err);
        yield put({ type: projectActions.getProjectCommentFailure.type });
      }
    },
  );
}

export function* watchWriteProjectComment() {
  const { setToaster } = coreActions;
  yield takeLatest(
    projectActions.writeProjectComment.type,
    function* (action: PayloadAction<WriteProjectCommentType>) {
      try {
        yield call(projectAPI.writeProjectComment, action.payload);
        yield put({
          type: projectActions.getProjectComment.type,
          payload: {
            projectId: action.payload.projectId,
            page: '1',
            limit: '10',
          },
        });
        yield put({
          type: projectActions.writeProjectCommentSuccess.type,
        });
      } catch (err) {
        sendErrorToSentry(err);
        yield put(
          setToaster({
            visible: true,
            message: '댓글 작성에 실패했습니다.\n다시 시도해 주세요.',
            type: 'error',
          }),
        );
      }
    },
  );
}
export const commentPageSelector = (state: RootState) => state.project.comment.commentPage;

export function* watchDeleteProjectComment() {
  const { setToaster } = coreActions;
  yield takeLatest(
    projectActions.deleteProjectComment.type,
    function* (action: PayloadAction<deleteProjectCommentArg>) {
      const commentPage: number = yield select(commentPageSelector);
      try {
        yield call(projectAPI.deleteProjectComment, action.payload);
        yield put({
          type: projectActions.getProjectComment.type,
          payload: {
            projectId: action.payload.projectId,
            limit: '10',
            page: String(commentPage),
          },
        });
      } catch (err) {
        sendErrorToSentry(err);
        yield put(
          setToaster({ visible: true, message: err?.data || err.statusText, type: 'error' }),
        );
      }
    },
  );
}

export function* watchGetCompetency() {
  yield takeLatest(projectActions.getCompetencies.type, function* (action: PayloadAction<number>) {
    try {
      const id = action.payload;
      const { data } = yield call(projectAPI.getCompetency, id);
      yield put({
        type: projectActions.getCompetenciesSuccess.type,
        payload: data,
      });
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: coreActions.setToaster.type,
        payload: {
          visible: true,
          message: err.statusText,
          type: 'error',
        },
      });
    }
  });
}

export function* projectSaga() {
  yield all([
    fork(watchGetProject),
    fork(watchGetProjectList),
    fork(watchGetProjectComment),
    fork(watchWriteProjectComment),
    fork(watchDeleteProjectComment),
    fork(watchGetCompetency),
  ]);
}
export default project.reducer;
