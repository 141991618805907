import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import keys from 'lodash/keys';

import { ParticipateBasic, ProfileType } from '#types/data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const participateBasicState: ParticipateBasic = {
  address: null,
  admissionDate: null,
  birthday: null,
  email: '',
  gender: null,
  graduationDate: null,
  major: '',
  militaryStatus: null,
  name: '',
  phone: '',
  schoolName: '',
  schoolLocation: '',
};
const participateBasic = createSlice({
  name: 'participateBasic',
  initialState: participateBasicState,
  reducers: {
    insertProfile: (state, action: PayloadAction<ProfileType>) => {
      if (action.payload) {
        const profile = action.payload;
        /**
         * * pick -> 객체에서 키의 배열을 뺀다 _.pick(object, ['a', 'c']); => { 'a': 1, 'c': 3 }
         * * intersection -> 두 배열의 공통값만 추출
         * ? redux에서의 필요한 keys 에 대응대는 값을 profile로부터 받는다.
         * ? 필요한 key만 있는 profile(values)에서 null인 값은 뺀다.
         * { a:0,b:2 ,e:4} <<{a:3,c:4,e:5}
         */
        const values = pick(profile, intersection(keys(state), keys(profile)));

        return {
          ...state,
          ...values,
          birthday: !profile.birthday ? null : profile.birthday,
          admissionDate: !profile.admissionDate ? null : profile.admissionDate,
          graduationDate: !profile.graduationDate ? null : profile.graduationDate,
        };
      }

      return state;
    },
    changeName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      return state;
    },
    changeAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
      return state;
    },
    changeAddmissionDate: (state, action: PayloadAction<Date | null>) => {
      state.admissionDate = action.payload;
      return state;
    },
    changeBirthday: (state, action: PayloadAction<Date | null>) => {
      state.birthday = action.payload;
      return state;
    },
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      return state;
    },
    changeGender: (state, action: PayloadAction<string>) => {
      state.gender = action.payload;
      return state;
    },
    changeGraduationDate: (state, action: PayloadAction<Date | null>) => {
      state.graduationDate = action.payload;
      return state;
    },
    changeMajor: (state, action: PayloadAction<string>) => {
      state.major = action.payload;
      return state;
    },
    changeMilitaryStatus: (state, action: PayloadAction<string>) => {
      state.militaryStatus = action.payload;
      return state;
    },
    changePhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
      return state;
    },
    changeSchoolName: (state, action: PayloadAction<string>) => {
      state.schoolName = action.payload;
      return state;
    },
    changeSchoolLocation: (state, action: PayloadAction<string>) => {
      state.schoolLocation = action.payload;
      return state;
    },
  },
});

export const participateBasicActions = participateBasic.actions;
export default participateBasic.reducer;
