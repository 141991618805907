import {
  GetRecruitmentListPayload,
  ToggleRecruitmentBookmarkOnListPayload,
} from '#types/redux/recruitmentModule';
import { RecruitmentFilterHistoryType } from '#types/data.d';
import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import {
  getCompanyRecruitmentListAPI,
  getSimilarRecruitmentListAPI,
  bookmarkRecruitmentNoticeAPI,
  unbookmarkRecruitmentNoticeAPI,
  getBookmarkedRecruitmentListAPI,
  getRecruitmentListByFilteringAPI,
} from '#lib/api/recruitment';
import {
  CompanyRecruitmentListQueries,
  RecruitmentQueries,
  SimilarRecruitmentListQueries,
} from '#types/apiQueries';
import { coreActions } from './core';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ApplicantFormDataType,
  GetRecruitmentListSuccessPayload,
  RecruitmentMockupType,
  RecruitmentState,
  RecruitmentType,
} from '#types/redux/recruitmentModule';
import { sendErrorToSentry } from '#lib/error';

export function isRecruitmentType(
  recruitment: RecruitmentType | RecruitmentMockupType,
): recruitment is RecruitmentType {
  return (recruitment as RecruitmentType).recruitmentServiceId !== undefined;
}

export const recruitmentState: RecruitmentState = {
  category: 'all',
  recruitmentList: {
    recruitments: [],
    count: 0,
    isLoading: true,
    queries: null,
  },
  recommandedRecruitmentList: [],
  companyRecruitmentList: [],
  bookmarkedRecruitmentList: [],
  errorMessage: '',
  tabStatus: '채용공고',
  isOnApplicationForm: false,
  isOnRecommendRecruitmentPopup: false,
  applicantFormData: {
    selectedResume: null,
    selectedPortfolio: null,
    selectedMiniinternPortfolio: null,
  },
  recruitmentFilterHistory: null,
};

// reducer
const recruitment = createSlice({
  name: 'recruitment',
  initialState: recruitmentState,
  reducers: {
    getRecruitmentList: (state, action: PayloadAction<GetRecruitmentListPayload>) => {
      state.recruitmentList.isLoading = true;
      if (action.payload.queries.page === '1') {
        state.recruitmentList.recruitments = recruitmentState.recruitmentList.recruitments;
        state.recruitmentList.count = recruitmentState.recruitmentList.count;
      }
      state.recruitmentList.queries = action.payload.queries;
    },
    getRecruitmentListSuccess: (state, action: PayloadAction<GetRecruitmentListSuccessPayload>) => {
      state.recruitmentList.isLoading = false;
      state.recruitmentList.count = action.payload.count;
      state.recruitmentList.recruitments = [
        ...state.recruitmentList.recruitments,
        ...action.payload.notices,
      ];
    },
    getRecruitmentListFailure: (state, _action: PayloadAction<any>) => {
      state.recruitmentList.isLoading = false;
    },
    getCompanyRecruitmentList: (state, _action: PayloadAction<CompanyRecruitmentListQueries>) => {
      return state;
    },
    getCompanyRecruitmentListSuccess: (state, action: PayloadAction<RecruitmentType[]>) => {
      state.companyRecruitmentList = action.payload;
    },
    getCompanyRecruitmentListFailure: (state, action: PayloadAction<any>) => {
      state.errorMessage = action.payload;
    },
    getSimilarRecruitmentList: (
      _state,
      _action: PayloadAction<SimilarRecruitmentListQueries>,
    ) => {},
    getSimilarRecruitmentListSuccess: (state, action: PayloadAction<RecruitmentType[]>) => {
      state.recommandedRecruitmentList = action.payload;
    },
    getBookmarkedRecruitmentList: _state => {},
    getBookmarkedRecruitmentListSuccess: (state, action: PayloadAction<RecruitmentType[]>) => {
      state.bookmarkedRecruitmentList = action.payload;
    },
    getBookmarkedRecruitmentListFailure: (state, action: PayloadAction<any>) => {
      state.errorMessage = action.payload;
    },

    toggleRecruitmentBookmarkOnList: (
      _state,
      _action: PayloadAction<ToggleRecruitmentBookmarkOnListPayload>,
    ) => {},

    toggleRecruitmentBookmarkOnListSuccess: (
      state,
      action: PayloadAction<ToggleRecruitmentBookmarkOnListPayload>,
    ) => {
      const recruitment = state.recruitmentList.recruitments.find(
        recruitment => recruitment.id === action.payload.recruitmentId,
      );

      if (recruitment) {
        recruitment.bookmarked = !action.payload.prevBookmarked;
      }
    },

    setCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    setTabStatus: (state, action: PayloadAction<RecruitmentState['tabStatus']>) => {
      state.tabStatus = action.payload;
    },
    setIsOnApplicationForm: (state, action: PayloadAction<boolean>) => {
      state.isOnApplicationForm = action.payload;
    },
    setIsOnRecommendRecruitmentPopup: (state, action: PayloadAction<boolean>) => {
      state.isOnRecommendRecruitmentPopup = action.payload;
    },
    setApplicantFormdata: (state, action: PayloadAction<ApplicantFormDataType>) => {
      state.applicantFormData = action.payload;
    },
    setRecruitmentFilterHistory(state, action: PayloadAction<RecruitmentFilterHistoryType>) {
      state.recruitmentFilterHistory = action.payload;
    },
  },
});

export const recruitmentActions = recruitment.actions;

// saga

function* watchGetRecruitmentList() {
  yield takeLatest(
    recruitmentActions.getRecruitmentList.type,
    function* (
      action: PayloadAction<{
        queries: RecruitmentQueries;
        filterData: RecruitmentFilterHistoryType;
      }>,
    ) {
      try {
        const { data } = yield call(
          getRecruitmentListByFilteringAPI,
          action.payload.queries,
          action.payload.filterData,
        );
        yield put({
          type: recruitmentActions.getRecruitmentListSuccess.type,
          payload: {
            notices: data.notices,
            count: data.count,
            page: action.payload.queries.page,
          },
        });
      } catch (err) {
        sendErrorToSentry(err);
        yield put({
          type: recruitmentActions.getRecruitmentListFailure.type,
        });
      }
    },
  );
}

function* watchGetCompanyRecruitmentList() {
  yield takeLatest(
    recruitmentActions.getCompanyRecruitmentList.type,
    function* (action: PayloadAction<CompanyRecruitmentListQueries>) {
      try {
        const { data } = yield call(getCompanyRecruitmentListAPI, action.payload);
        yield put({
          type: recruitmentActions.getCompanyRecruitmentListSuccess.type,
          payload: data,
        });
      } catch (err) {
        sendErrorToSentry(err);
      }
    },
  );
}

function* watchGetSimilarRecruitmentlist() {
  yield takeLatest(
    recruitmentActions.getSimilarRecruitmentList.type,
    function* (action: PayloadAction<SimilarRecruitmentListQueries>) {
      try {
        const { data } = yield call(getSimilarRecruitmentListAPI, action.payload);
        yield put({
          type: recruitmentActions.getSimilarRecruitmentListSuccess.type,
          payload: data,
        });
      } catch (err) {
        sendErrorToSentry(err);
      }
    },
  );
}

function* watchGetBookmarkedRecruitmentList() {
  yield takeLatest(recruitmentActions.getBookmarkedRecruitmentList.type, function* () {
    try {
      const { data } = yield call(getBookmarkedRecruitmentListAPI);
      yield put({
        type: recruitmentActions.getBookmarkedRecruitmentListSuccess.type,
        payload: data,
      });
    } catch (err) {
      sendErrorToSentry(err);
    }
  });
}

function* watchToggleRecruitmentBookmark() {
  yield takeLatest(
    recruitmentActions.toggleRecruitmentBookmarkOnList.type,
    function* (action: PayloadAction<ToggleRecruitmentBookmarkOnListPayload>) {
      try {
        yield call(
          action.payload.prevBookmarked
            ? unbookmarkRecruitmentNoticeAPI
            : bookmarkRecruitmentNoticeAPI,
          action.payload.recruitmentId,
        );
        yield put({
          type: recruitmentActions.toggleRecruitmentBookmarkOnListSuccess.type,
          payload: action.payload,
        });
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: action.payload.prevBookmarked
              ? '관심 채용을 해제했습니다.'
              : '관심 채용으로 등록되었습니다.\n마이 커리어에서 확인해 주세요.',
            type: 'success',
          },
        });
      } catch (err) {
        sendErrorToSentry(err);
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: '잠시 후 다시 시도해주세요.',
            type: 'error',
          },
        });
      }
    },
  );
}

// export

export function* recruitmentSaga() {
  yield all([
    fork(watchGetRecruitmentList),
    fork(watchGetCompanyRecruitmentList),
    fork(watchGetSimilarRecruitmentlist),
    fork(watchGetBookmarkedRecruitmentList),
    fork(watchToggleRecruitmentBookmark),
  ]);
}

export default recruitment.reducer;
