'use client';

import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { useAppSelector } from '#lib/hooks/useAppSelector';
import CoreContainer from './CoreContainer';
import EmailAuthenticationHeader from '../../(pages)/auth/email-authentication/_components/EmailAuthenticationHeader';
import FooterWrapper from '../base/footer/FooterWrapper';
import useUserInfo from '#lib/hooks/useUserInfo';
import routes from '#constants/routes';
import MeMobileHeader from '../../(pages)/me/_components/MeMobileHeader';
import ListPageStickyHeader from '#components/common/header/ListPageStickyHeader';
import MePcSidebar from '../../(pages)/me/_components/MePcSidebar';
import responsive from '@miniintern/styles/responsive';
import useModal from '#lib/hooks/useModal';
import useGTM from '#lib/hooks/useGTM';
import { changeDormancyStatus } from '#lib/api/me';
import { sendErrorToSentry } from '#lib/error';
import ReleaseDormancyUserPopup from '#components/account/popup/ReleaseDormancyUserPopup';
import TagManager from 'react-gtm-module';
import Header from '#components/base/header/Header';

interface BasicTemplateBlockProps {
  mePage: boolean;
}

const BasicTemplateBlock = styled.div<BasicTemplateBlockProps>`
  position: relative;
  display: block;

  main {
    position: relative;
    min-height: calc(100vh - 211px);
  }

  ${({ mePage }) =>
    mePage &&
    css`
      .main-box {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }

      main {
        width: 100%;
        max-width: 1160px;
        overflow-x: hidden;
      }

      @media (max-width: ${responsive.medium}) {
        .main-box {
          display: flex;
          flex-direction: column;
        }
        main {
          width: initial;
          padding: 0;
        }
      }
    `}

  .miniintern-approve-popup-description {
    margin-top: 24px;
    text-align: center;
  }
`;

type TagManagerArgsType = {
  dataLayer: {
    user_id: number | null;
  };
};

const BasicTemplate: React.FC<React.PropsWithChildren> = ({ children }) => {
  const pathname = usePathname();
  const params = useParams<{ id: string; urlSlug: string; applicationId: string }>();
  const searchParams = useSearchParams();

  const { userInfo, isLoadingToGetUserInfo } = useUserInfo({ revalidateIfStale: true });
  const core = useAppSelector(state => state.core);
  const mePage = ['me'].includes(pathname!.split('/')[1]);
  const isAdminMode =
    searchParams?.get('mode') === 'preview' ||
    !!searchParams?.get('admin_mode') ||
    searchParams?.get('mode') === 'admin';

  const { openModal } = useModal();
  const { pushGtmEvent } = useGTM();

  useEffect(() => {
    if (isLoadingToGetUserInfo) return;

    const isAdsAgreed = window.localStorage.getItem('isAdsAgreed');
    if (isAdsAgreed && pathname !== routes.signup) {
      window.localStorage.removeItem('isAdsAgreed');
    }

    const session_user_id = window.sessionStorage.getItem('user_id');
    const user_id = session_user_id == null ? undefined : Number(session_user_id);

    if (!userInfo?.id) {
      window.sessionStorage.removeItem('user_id');
    }

    if (userInfo && userInfo.id !== user_id) {
      window.sessionStorage.setItem('user_id', `${userInfo.id}`);
    }

    //? google-tag-manager
    const tagManagerArgs: TagManagerArgsType = {
      dataLayer: {
        user_id: user_id || userInfo?.id || null,
      },
    };

    TagManager.dataLayer(tagManagerArgs);
    TagManager.initialize({
      gtmId: process.env.GOOGLE_TAG_MANAGER_ID || '',
    });

    //? facebook-pixel
    if (process.env.NODE_ENV === 'production') {
      import('react-facebook-pixel')
        .then(x => x.default)
        .then(ReactPixel => {
          ReactPixel.init(process.env.FACEBOOK_PIXEL_ID || '');
          ReactPixel.pageView();
        });
    }
  }, [isLoadingToGetUserInfo, userInfo]);

  useEffect(() => {
    if (userInfo) {
      pushGtmEvent({
        event: 'login_done',
        auth_method: userInfo?.loginMethod,
      });
      pushGtmEvent({
        is_login_click: false,
      });
    }
  }, [userInfo?.id]);

  //* 휴면게정 해제 팝업
  useEffect(() => {
    const releaseDormancyUser = async () => {
      try {
        await changeDormancyStatus();
        openModal({
          Component: () => <ReleaseDormancyUserPopup />,
        });
      } catch (error) {
        sendErrorToSentry(error);
      }
    };
    if (isLoadingToGetUserInfo) return;
    if (userInfo?.dormancyUser) {
      if (userInfo?.dormancyUser.isDeleted) return;
      releaseDormancyUser();
    }
  }, [userInfo?.dormancyUser, isLoadingToGetUserInfo]);

  /** 내 페이지 라우트들 */
  const isMePage = useMemo(() => {
    if (!pathname) return false;
    if (!params) return false;

    return [
      routes.meHappyfolioPrevew,
      routes.meHappyfolioRegister,
      routes.meHappyfolioEdit(params.id),
      routes.meEventRegister,
      routes.meEventEdit(params.id),
      routes.meDocumentsResumeCreate,
      routes.meDocumentsResumeUpdate(params.urlSlug),
      routes.meApplicationsApplySettleAccount(params.urlSlug, params.applicationId),
      routes.meApplicationsJoined(params.urlSlug),
      routes.meAccount,
    ].includes(pathname);
  }, [pathname, params]);

  /** 헤더를 숨길 라우트들 */
  const hideHeader = useMemo(() => {
    if (!pathname) return false;

    return ([routes.meHappyfolioPrevew, routes.meDocumentsResumeCreate] as string[]).includes(
      pathname,
    );
  }, [pathname]);
  /** 헤더 고정 라우트들 */
  const stickyHeader = useMemo(() => {
    if (!pathname) return;

    return (
      [routes.event, routes.happyfolio, routes.project, routes.recruitment] as string[]
    ).includes(pathname);
  }, [pathname]);
  /** 푸터 없앨 라우트들 */
  const hideFooter = useMemo(() => {
    if (!pathname) return;

    return (
      [
        routes.event,
        routes.happyfolio,
        routes.project,
        routes.recruitment,
        routes.skillup,
        routes.meDocumentsResumeCreate,
      ] as string[]
    ).includes(pathname);
  }, [pathname]);
  /** 투명 헤더 라우트들 */
  const transparentHeader = useMemo(() => {
    if (!pathname) return false;
    if (!params) return false;

    return ([routes.skillup, routes.skillupDetail(params.id)] as string[]).includes(pathname);
  }, [pathname, params]);
  /** 기본 템플릿을 사용하지 않는 라우트들 */
  const notUseBasicTemplate = useMemo(() => {
    if (!pathname) return false;
    if (!params) return false;

    return ([routes.skillupDetailCoursePlay(params.id)] as string[]).includes(pathname);
  }, [pathname, params]);

  if (notUseBasicTemplate) return children;

  return (
    <>
      {!isLoadingToGetUserInfo &&
        userInfo &&
        !userInfo.verified &&
        core.isShownEmailAuthenticationHeader && <EmailAuthenticationHeader />}
      <BasicTemplateBlock mePage={mePage && !isMePage}>
        <CoreContainer />
        {isAdminMode ? (
          <></>
        ) : (
          !hideHeader && (
            <Header stickyHeader={stickyHeader} transparentHeader={transparentHeader} />
          )
        )}
        <div className="main-box">
          {mePage && !isMePage && (
            <>
              <ListPageStickyHeader className="visible-on-mobile">
                <MeMobileHeader />
              </ListPageStickyHeader>
              <MePcSidebar className="visible-on-pc" />
            </>
          )}
          <main>{children}</main>
        </div>
        {!hideFooter && !isAdminMode && <FooterWrapper />}
      </BasicTemplateBlock>
    </>
  );
};

export default BasicTemplate;
