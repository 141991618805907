import { coreActions } from './core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import * as authAPI from '#lib/api/auth';
import { sendErrorToSentry } from '#lib/error';
import {
  AuthState,
  ResetPasswordRequestPayload,
  ResetPasswordSuccessPayload,
  SendEmailRequestPayload,
} from '#types/redux/authModule';

export const authState: AuthState = {
  duplicated: false,
  loading: false,
  isSended: false,
  isUpdated: false,
  apiError: '',
};

export const auth = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    initialize: () => authState,
    sendEmail: (state, _action: PayloadAction<SendEmailRequestPayload>) => {
      state.loading = true;
    },
    sendEmailSuccess: state => {
      state.isSended = true;
      state.loading = false;
    },
    resetPassword: (state, _action: PayloadAction<ResetPasswordRequestPayload>) => {
      state.loading = true;
    },
    resetPasswordSuccess: (state, action: PayloadAction<ResetPasswordSuccessPayload>) => {
      const { updated } = action.payload;
      state.loading = false;
      state.isUpdated = updated;
    },
    resetPasswordFailure: (state, action) => {
      state.loading = false;
      state.apiError = action.payload;
    },
    setDuplicate: state => {
      state.duplicated = false;
    },
  },
});

export const authActions = auth.actions;

export function* watchSendEmail() {
  yield takeLatest(
    authActions.sendEmail,
    function* (action: PayloadAction<SendEmailRequestPayload>) {
      const { email } = action.payload;
      try {
        yield call(authAPI.sendEmail, email);
        yield put({
          type: authActions.sendEmailSuccess.type,
        });
      } catch (err) {
        sendErrorToSentry(err);
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message: err?.data || err.stateText,
            type: 'error',
          },
        });
      }
    },
  );
}

export function* watchResetPassword() {
  yield takeLatest(
    authActions.resetPassword,
    function* (action: PayloadAction<ResetPasswordRequestPayload>) {
      const { key, password } = action.payload;
      try {
        const { data } = yield call(authAPI.resetPassword, {
          key,
          password,
        });
        yield put({
          type: authActions.resetPasswordSuccess.type,
          payload: data,
        });
      } catch (err) {
        sendErrorToSentry(err);
        let message = null;
        if (err) {
          switch (err.status) {
            case 400:
              message = '업데이트 key가 올바른 형식이 아닙니다.';
              break;
            case 404:
              message = 'key가 존재하지 않습니다.\n비밀번호 변경을 다시 시도해 주세요.';
              break;
            case 409:
              message = '이미 비밀번호 변경을 신청하셨습니다.';
              break;
            case 410:
              message = '비밀번호 변경 24시간을 초과했습니다.\n비밀번호 변경을 다시 시도해 주세요.';
              break;
            case 422:
              message = '허용되지 않는 비밀번호 형식입니다.\n비밀번호 변경을 다시 시도해 주세요.';
              break;
            default:
              message = '알 수 없는 에러가 발생했습니다.';
          }
        }
        yield put({
          type: coreActions.setToaster.type,
          payload: {
            visible: true,
            message,
            type: 'error',
          },
        });
      }
    },
  );
}

export function* authSaga() {
  yield all([fork(watchSendEmail), fork(watchResetPassword)]);
}
export default auth.reducer;
