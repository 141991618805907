import SupportIconSVG from '@svg/me/snb/support-sidebar-gray-icon.svg';
import ResumeIconSVG from '@svg/me/snb/resume-sidebar-gray-icon.svg';
import ResumeBlueIconSVG from '@svg/me/snb/resume-sidebar-blue-icon.svg';
import MiniinternIconSVG from '@svg/me/snb/miniintern-sidebar-gray-icon.svg';
import SkillupIconSVG from '@svg/me/snb/skillup-sidebar-gray-icon.svg';
import MClassIconSVG from '@svg/me/snb/mclass-sidebar-gray-icon.svg';
import HappyfolioIconSVG from '@svg/me/snb/happyfolio-sidebar-gray-icon.svg';
import SupportBlueIconSVG from '@svg/me/snb/support-sidebar-blue-icon.svg';
import MiniinternBlueIconSVG from '@svg/me/snb/miniintern-sidebar-blue-icon.svg';
import SkillupBlueIconSVG from '@svg/me/snb/skillup-sidebar-blue-icon.svg';
import MClassBlueIconSVG from '@svg/me/snb/mclass-sidebar-blue-icon.svg';
import HappyfolioBlueIconSVG from '@svg/me/snb/happyfolio-sidebar-blue-icon.svg';
import routes from '#constants/routes';

export type MeMenuType = {
  inactivatedIconSVG: JSX.Element;
  activatedIconSVG: JSX.Element;
  value: string;
  title: string;
  link: string;
  disabled: boolean;
  subMenu?: {
    title: string;
    link: string;
    disabled: boolean;
    gtmLabel: string;
  }[];
  gtmLabel: string;
};

const useMeMenu = () => {
  const meMenuList: MeMenuType[] = [
    {
      inactivatedIconSVG: <ResumeIconSVG />,
      activatedIconSVG: <ResumeBlueIconSVG />,
      title: '지원서류 관리',
      value: 'documents',
      link: routes.meDocuments,
      disabled: false,
      gtmLabel: 'my_click_menu_docs',
    },
    {
      inactivatedIconSVG: <SupportIconSVG />,
      activatedIconSVG: <SupportBlueIconSVG />,
      title: '지원 현황',
      value: 'applications',
      link: routes.meApplications,
      disabled: false,
      subMenu: [
        {
          title: '지원 현황 관리',
          link: routes.meApplications,
          disabled: false,
          gtmLabel: 'my_click_menu_application_status',
        },
        {
          title: '헤드헌팅 설정',
          link: routes.meHeadhunting,
          disabled: false,
          gtmLabel: 'my_click_menu_application_headhunting',
        },
      ],
      gtmLabel: 'my_click_menu_application',
    },
    {
      inactivatedIconSVG: <MiniinternIconSVG />,
      activatedIconSVG: <MiniinternBlueIconSVG />,
      title: '미니인턴',
      value: 'project',
      link: routes.meProject,
      disabled: false,
      gtmLabel: 'my_click_menu_miniintern',
    },
    {
      inactivatedIconSVG: <SkillupIconSVG />,
      activatedIconSVG: <SkillupBlueIconSVG />,
      title: '스킬업',
      value: 'skillup',
      link: routes.meSkillupCourse,
      disabled: false,
      subMenu: [
        {
          title: '스킬업 강의',
          link: routes.meSkillupCourse,
          disabled: false,
          gtmLabel: 'my_click_menu_skillup_course',
        },
        {
          title: '결제 내역',
          link: routes.meSkillupPayHistory,
          disabled: false,
          gtmLabel: 'my_click_menu_skillup_payhistory',
        },
      ],
      gtmLabel: 'my_click_menu_skillup',
    },
    {
      inactivatedIconSVG: <MClassIconSVG />,
      activatedIconSVG: <MClassBlueIconSVG />,
      title: 'M클래스',
      value: 'event',
      link: routes.meEvent,
      disabled: false,
      subMenu: [
        {
          title: '참여 M클래스',
          link: routes.meEvent,
          disabled: false,
          gtmLabel: 'my_click_menu_mclass_manage', //? gtmLabel 논의 필요
        },
        {
          title: '호스트 관리',
          link: routes.meEventHost,
          disabled: false,
          gtmLabel: 'my_click_menu_mclass_hostmanage', //? gtmLabel 논의 필요
        },
      ],
      gtmLabel: 'my_click_menu_mclass',
    },
    {
      inactivatedIconSVG: <HappyfolioIconSVG />,
      activatedIconSVG: <HappyfolioBlueIconSVG />,
      title: '해피폴리오',
      value: 'happyfolio',
      link: routes.meHappyfolio(),
      disabled: false,
      subMenu: [
        {
          title: '구매 관리',
          link: routes.meHappyfolio(),
          disabled: false,
          gtmLabel: 'my_click_menu_happyfolio_manage',
        },
        {
          title: '호스트 관리',
          link: routes.meHappyfolioHost(),
          disabled: false,
          gtmLabel: 'my_click_menu_happyfolio_hostmanage',
        },
      ],
      gtmLabel: 'my_click_menu_happyfolio',
    },
  ];

  return { meMenuList };
};

export default useMeMenu;
