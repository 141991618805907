'use client';

import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '@miniintern/styles/palette';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DEFAULT_SWIPER_OPTION } from '#lib/swiperSettings';
import useMeMenu from './useMeMenu';
import { usePathname } from 'next/navigation';

const MeMobileHeaderBlock = styled.div`
  .toc-swiper-slide {
    display: flex;
    overflow: hidden;
    border-bottom: 1px solid ${palette.gray_300};

    .swiper-wrapper {
      display: -webkit-box;
      justify-content: center;

      .swiper-slide {
        width: max-content;

        &:first-child {
          margin-left: 30px;
        }
      }
    }
  }
  .sub-menu-list-box {
    display: flex;
    justify-content: center;
    gap: 60px;
    padding: 11px 0 12px 0;
    background-color: ${palette.gray_100};
  }
`;

const LinkWrapper = styled.div<{ isFocused: boolean }>`
  display: block;
  height: 44px;
  line-height: 27px;
  padding: 9px 0 8px;
  margin: 0 25px;
  cursor: pointer;

  a {
    padding: 10px 0;
    color: ${props => (props.isFocused ? palette.blue_500 : palette.black)};
    border-bottom: ${props => props.isFocused && `2px solid ${palette.blue_500}`};
  }
`;

const SubLinkListItem = styled.li<{ isFocused: boolean; disabled: boolean }>`
  position: relative;
  & + & {
    &:before {
      content: '';
      border: 1px solid ${palette.gray_300};
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -30px;
    }
  }

  a,
  span {
    color: ${props => (props.disabled ? palette.gray_300 : palette.gray_500)};
  }

  ${props =>
    props.isFocused &&
    css`
      a {
        color: ${palette.black};
      }
    `}
`;

const MeMobileHeader: React.FC = () => {
  const pathname = usePathname();
  const [isVisible, setIsVisible] = useState(true);
  const position = useRef(0);
  const { meMenuList } = useMeMenu();
  const currentPathArr = pathname?.split('/').slice(1, 4) ?? [];
  const menuValueList = meMenuList.map(menu => menu.value);
  const currentPageInfo = meMenuList.find(menu => menu.value === currentPathArr[1]);

  useEffect(() => {
    const handleScroll = () => {
      const SUB_MENU_HEIGHT = 43;
      if (Math.abs(position.current - window.scrollY) <= SUB_MENU_HEIGHT) return;
      position.current === 0
        ? setIsVisible(true)
        : setIsVisible(position.current >= window.scrollY);
      position.current = window.scrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [position, pathname]);

  return (
    <MeMobileHeaderBlock>
      <Swiper
        {...DEFAULT_SWIPER_OPTION}
        initialSlide={menuValueList.indexOf(currentPathArr[1]) - 1}
        className="toc-swiper-slide"
      >
        {meMenuList.map((menu, i) => (
          <SwiperSlide key={i}>
            <LinkWrapper isFocused={menu.value === currentPathArr[1]}>
              <Link href={menu.link || ''} passHref data-gtm-label={menu.gtmLabel}>
                {menu.title}
              </Link>
            </LinkWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
      {isVisible && currentPageInfo?.subMenu && (
        <ul className="sub-menu-list-box">
          {currentPageInfo?.subMenu.map((sub, i) => (
            <SubLinkListItem
              key={i}
              className="sub-menu-list"
              isFocused={currentPathArr.slice(-1)[0] === sub.link.split('/').slice(-1)[0]}
              disabled={sub.disabled}
            >
              {!sub.disabled ? (
                <Link href={sub.link || ''} passHref data-gtm-label={sub.gtmLabel}>
                  {sub.title}
                </Link>
              ) : (
                <span key={i}>{sub.title}</span>
              )}
            </SubLinkListItem>
          ))}
        </ul>
      )}
    </MeMobileHeaderBlock>
  );
};

export default React.memo(MeMobileHeader);
