import { RecruitmentFilterHistoryType } from '#types/data.d';
import axios from './apiClient';
import { makeUrlQueries } from '@miniintern/utils';
import {
  RecruitmentQueries,
  SimilarRecruitmentListQueries,
  CompanyRecruitmentListQueries,
} from '#types/apiQueries';
import { SelectedApplicantFormDataType } from '#types/data';
import { ChallengeRecruitAPIBodyType, PostRecruitmentInflowPathBodyType } from '#types/apiParams';
import { RecruitmentDetailType, RecruitmentType } from '#types/redux/recruitmentModule';
import { fetchWrapper } from '#lib/fetchWrapper';
import { RequestInit } from 'next/dist/server/web/spec-extension/request';

export const getCompanyRecruitmentListAPI = (queries: CompanyRecruitmentListQueries) =>
  axios.get(
    `/api/v3/recruitment-notices/companies/${queries.companyId}/${makeUrlQueries(
      'notices/active',
      queries,
    )}`,
  );

export const getSimilarRecruitmentListAPI = (
  silmilarRecruitmentListQueries: SimilarRecruitmentListQueries,
) =>
  axios.get(
    `/api/v3/recruitment-notices/similar?keyword=${silmilarRecruitmentListQueries.categories}&limit=${silmilarRecruitmentListQueries.limit}&noticeId=${silmilarRecruitmentListQueries.noticeId}`,
  );

export const getBookmarkedRecruitmentListAPI = () =>
  axios.get('/api/v2/me/recruitments/notices/bookmarks');

export const getRecruitmentApiKey = (urlSlug: string | string[]) =>
  `/api/v3/recruitment-notices/${urlSlug}`;

export const getRecruitmentAPI = (urlSlug: string | string[]) =>
  axios.get<RecruitmentDetailType>(getRecruitmentApiKey(urlSlug));

export const fetchRecruitmentAPI = (urlSlug: string | string[], option: RequestInit = {}) =>
  fetchWrapper<RecruitmentDetailType>(getRecruitmentApiKey(urlSlug), option);

export const applyRecruitmentAPI = (selectedApplicantFormData: SelectedApplicantFormDataType) =>
  axios.post<{ applicationId: number }>(
    '/api/v3/recruitment-notices/applications',
    selectedApplicantFormData,
  );

export const bookmarkRecruitmentNoticeAPI = (noticeId: number) =>
  axios.post(`/api/v3/recruitment-notices/bookmarks/${noticeId}`);
export const unbookmarkRecruitmentNoticeAPI = (noticeId: number) =>
  axios.delete(`/api/v3/recruitment-notices/bookmarks/${noticeId}`);

export const postChallengeRecruitAPI = (body: ChallengeRecruitAPIBodyType) =>
  axios.post('/api/v3/recruitment-notices/challenges', body);

export const postRecruitmentInflowPath = ({
  applicationId,
  surveyCategory,
  question,
  answer,
}: PostRecruitmentInflowPathBodyType) =>
  axios.patch(`/api/v3/recruitment-notices/applications/${applicationId}/survey`, {
    surveyCategory,
    question,
    answer,
  });

export const getRecruitmentListByFilterApiKey = (query: RecruitmentQueries) =>
  makeUrlQueries('/api/v3/recruitment-notices', query);

//* 채용공고 전체 리스트 불러오기 및 검색,정렬,필터 기능 포함
export const getRecruitmentListByFilteringAPI = (
  queries: RecruitmentQueries,
  filterData: RecruitmentFilterHistoryType,
) =>
  axios.post<{ notices: RecruitmentType[]; count: number }>(
    getRecruitmentListByFilterApiKey(queries),
    filterData,
  );

export const fetchRecruitmentListByFiltering = (
  queries: RecruitmentQueries,
  filterData: RecruitmentFilterHistoryType,
  option: RequestInit = {},
) => {
  return fetchWrapper<{ notices: RecruitmentType[]; count: number }>(
    getRecruitmentListByFilterApiKey(queries),
    {
      method: 'POST',
      body: JSON.stringify(filterData),
      ...option,
    },
  );
};

//* 채용공고 전체 리스트 불러오기 필터정보 저장
export const postRecruitmentsNoticesFilterAPI = (filterData: RecruitmentFilterHistoryType) =>
  axios.post('/api/v3/recruitment-notices/filter', filterData);
